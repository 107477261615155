import { useEffect, useState } from 'react';
import int from '../../../../CSS/SectionX_Interactive.module.css';

export default function StorageSection({
    entries,
    entries2,
    entries3,
    showNames,
    updateIds,
    showStones,
    stones1,
    stones2,
    phase
})
{
    return(
        <div id = "StorageContainer" className = {int.StorageContainer}>
            <div className={`${int.FullCol2Long} ${int.Hidden}`} id = "FCH0">
                <p className = {`${int.BasicText2}`}>Storage Space 0: <br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Name: <i>User</i></span>
                    <br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Entries: <i>{entries}</i></span>
                </p>
                <ul id = "ListItems" className = {int.StorageItems} style = {{width: "100%"}}>
                    <li>id: uint256 (type 1)</li>
                    <li>name: string (type 6)</li>
                    <li id = "InvisLi" className = {int.LiHide}>stones: uint256 (type 1)</li>
                    <li id = "InvisLi2" className = {`${(phase <= 14) ? int.LiHide: ''} ${(phase <= 14) ? int.HiddenAbs : ''}`}>tetherLocked: uint256 (type 1)</li>
                </ul>

                <p 
                    id = "StorageItems2"
                    style = {{marginTop: 0}} 
                    className = {`${int.BasicText2} ${int.HiddenAbs}`}
                >Stored Data:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Slot: <i>0 </i> <br/> 
                        Name: {(showNames) ? "Bob" : ""} 
                        | Id: {(updateIds) ? 1 : 0} 
                        |  {(showStones) ? `Stones: ${stones1}` : ''} 
                        
                        {(phase > 14) ? ` | TetherLocked: ${phase >= 17 ? 50 : 0}` : ""}
                    </span>
                    <br/><br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                    Slot: <i>1 </i> <br/> Name: {(showNames) ? "Sally" : ""} | Id: {(updateIds) ? 2 : 0} |  {(showStones) ? `Stones: ${stones2}` : ''}
                    {(phase > 14) ? ` | TetherLocked: ${phase >= 17 ? 50 : 0}` : ""}
                    </span>
                    <br/>
                </p>

                <p id = "SS2" style = {{marginTop: "40px"}} className = {`${int.BasicText2} ${int.HiddenAbs}`}>Storage Space 1:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Name: <i>Token</i></span>
                    <br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Entries: <i>{`${(phase >= 13) ? 1 : 0}`}</i></span>
                </p>

                <p 
                    id = "StorageItems3"
                    style = {{marginTop: "20px"}} 
                    className = {`${int.BasicText2} ${int.HiddenAbs}`}
                >Stored Data:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Slot: <i>0 </i><br/> totalSupply: <i>{100}</i> | name: <i>"Stone Token"</i>
                    </span>
                </p>

                <ul id = "ListItems2" className = {`${int.StorageItems} ${int.HiddenAbs}`} style = {{width: "100%"}}>
                    <li>circulatingSupply: uint256 (type 1)</li>
                    <li>tokenName: string (type 6)</li>
                </ul>

                <p id = "SS3" style = {{marginTop: "40px"}} className = {`${int.BasicText2} ${int.HiddenAbs}`}>Storage Space 2:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Name: <i>MarketOffers</i></span>
                    <br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Entries: <i>{(phase >= 16) ? "5 billion" : 0}</i></span>
                </p>
                <ul id = "ListItems3" className = {`${int.StorageItems} ${int.HiddenAbs}`} style = {{width: "100%"}}>
                    <li>actual: string</li>
                    <li>(decoded field) stones: uint256</li>
                    <li>(decoded field) price: uint256</li>
                </ul>

                <p 
                    id = "StorageItems4"
                    className = {`${int.BasicText2} ${(phase < 15) ? int.HiddenAbs : ''}`}
                    style = {{marginTop: "0"}}
                >Stored Data:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Slot: <i>0 </i><br/> stones: <i>0</i> | price: <i>0</i>
                    </span><br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        Slot: <i>1 </i><br/> stones: <i>{(phase < 17) ? 0 : 10}</i> | 
                        price: <i>{(phase < 17) ? 0 : 20}</i>
                    </span>
                </p>


                <p style = {{marginBottom: "40px", marginTop: "20px"}} id = "Permissions" className = {`${int.BasicText2}`}>
                    Permissions:<br/>
                    <span style = {{fontSize: "12px", color: 'white'}}>
                        LogicA (Token): <i>{phase >= 16 ? 0: 2}</i> <br/> 
                        LogicB (Market): <i>{phase >= 16 ? 0: 2}</i> <br/>
                        {(phase >= 16) ? `Token V2: 2` : ''} <br/>
                        {phase >= 16 ? ` Market V2: 2` : ''}
                    </span>
                </p>

            </div>

            <div id = "Terminal" className = {int.Terminal}>
                <p id = "Terminal1">init_create([1, 6], [256, 0])</p>
                <p id = "Terminal2">push(2, 0)</p>
                <p id = "Terminal3">insert_new_member(1, 256, 0)</p>
                <p id = "Terminal4">1) put(40, 2, 0, 0)</p>
                <p id = "Terminal5">2) put(20, 2, 1, 0)</p>
                <p id = "Terminal6">1) put_string("Bob", 1, 0, 0)</p>
                <p id = "Terminal7">2) put_string("Sally", 1, 1, 0)</p>
                <p id = "Terminal8">3) put(1, 0, 0, 0)</p>
                <p id = "Terminal9">4) put(2, 0, 1, 0)</p>
                <p id = "Terminal10">1) init_create([1, 6], [256, 0])</p>
                <p id = "Terminal11">2) init_create([6], [0])</p>
                <p id = "Terminal12">1) push(1, 1)</p>
                <p id = "Terminal13">2) put(100 * 1e18, 0, 0, 1)</p>
                <p id = "Terminal14">3) put_string("Stone Token", 0, 0, 1)</p>
                <p id = "Terminal15">insert_new_member(1, 256, 1)</p>
            </div>
        </div>
    )
}
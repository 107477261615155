import gsap from "gsap"
import { Phase10_Interactive, Phase5_Interactive, Phase7_Interactive, Phase8_Interactive, Phase9_Interactive, Phase_Interactive_Basic } from "../../../../Animations/SectionX_Animations"
import DemoContentSection from "../Scaffolds/DemoContentSection"
import DemoContentSectionInRow from "../Scaffolds/DemoContentSectionInRow"
import LongContentSection from "../Scaffolds/LongContentSection"
import { PhaseHeadings } from "./PhaseHeadings"
import { PhaseOnClick } from "./PhaseOnClick"
import { PhaseSubText } from "./PhaseSubText"


export const PhaseData = ({
        setPhase,
        setEntries, 
        setEntries2,
        setEntries3,
        setShowStones,
        setStones1,
        setStones2,
        stones1,
        stones2,
        setShowNames,
        setUpdateIds,
        setSpecialPhase1Complete
    }) => {

    return {
        Phase1: (
            <DemoContentSection 
                dropDown={true}
                containerId = "SectionX_IntroWrapper"
                headingText = "Interactive Demo"
                subText = {PhaseSubText.Phase1}
                onClick = {PhaseOnClick.Phase1}
                buttonText = "Begin"
                alignLeft = {true}
            />
        ),
        Phase2: (
            <DemoContentSection 
                dropDown={false}
                containerId = "SectionX_IntroWrapper2"
                headingText = "What is HoneyBadger?"
                subText = {PhaseSubText.Phase2}
                onClick = {PhaseOnClick.Phase2}
                buttonText = "Add smart contracts"
                hidden = {true}
            />
        ),
        Phase3: (
            <DemoContentSection 
                dropDown={false}
                containerId = "SectionX_IntroWrapper3"
                headingText = "Permissions"
                subText = {PhaseSubText.Phase3}
                onClick = {PhaseOnClick.Phase3}
                buttonText = "Apply Permissions"
                hidden={true}
                alignLeft={true}
            />
        ),
        Phase4: (
            <DemoContentSection 
                dropDown={false}
                containerId = "SectionX_IntroWrapper4"
                headingText = "Create a Storage Space"
                subText = {PhaseSubText.Phase4}
                onClick = {PhaseOnClick.Phase4}
                buttonText = "Create Storage Space"
                hidden={true}
            />
        ),
        Phase5: (
            <DemoContentSectionInRow 
                containerId={"FCH1"}
                header={"Storage Spaces Explained"}
                subText={PhaseSubText.Phase5}
                hasButton={false}
            />
        ),
        Phase6: (
            <DemoContentSectionInRow 
                containerId={"FCH2"}
                header={"Init_create"}
                subText={PhaseSubText.Phase6}
                hasButton={false}
            />
        ),
        Phase7:(
            <DemoContentSectionInRow 
                containerId={"FCH3"}
                header={"Next Step: Creating Users"}
                subText={PhaseSubText.Phase7}
                hasButton={true}
                onClick={() => {
                    Phase5_Interactive()
                    setEntries(2)
                }}
            />
        ),
        Phase8: (
            <DemoContentSectionInRow 
                containerId={"FCH4"}
                header={"The Push Function"}
                subText={PhaseSubText.Phase8}
                hasButton={true}
                onClick={PhaseOnClick.Phase8}
            />
        ),
        Phase9: (
            <DemoContentSectionInRow 
                containerId={"FCH5"}
                header={"Adding A New Field"}
                subText={PhaseSubText.Phase9}
                hasButton={true}
                onClick={() => {Phase7_Interactive({setShowStones})}}
            />
        ),
        Phase10:  (
            <DemoContentSectionInRow 
            containerId={"FCH6"}
            header={"Storing stone collections"}
            subText={PhaseSubText.Phase10}
            hasButton={true}
            onClick={() => {
                    Phase8_Interactive({
                    setStones1,
                    setStones2,
                    stones1: 40,
                    stones2: 20
                })
            }}
        />
        ),
        Phase11: (
            <DemoContentSectionInRow 
            containerId={"FCH7"}
            header={"Storing Names and Ids"}
            subText={PhaseSubText.Phase11}
            hasButton={true}
            onClick={() => {
                Phase9_Interactive({
                    setShowNames,
                    setUpdateIds
                })
            }}/>
        ),
        Phase12: (
            <LongContentSection 
                firstSubtext={PhaseSubText.Phase12[0]}
                hiddenSubtexts={PhaseSubText.Phase12[1]}
                setComplete={setSpecialPhase1Complete}
                hiddenIdPrefix={"PD_"}
                containerId = "FCH8"
                headerId = "FCH8H"
                firstHeading={"Cross-Contract"}
                hiddenHeadings={PhaseHeadings.Phase12}
                functions = {
                    {
                        0: {
                            func: () => {
                                gsap.timeline({})
                                .to("#I1", {opacity: 0})
                                .to("#I2", {opacity: 1})
                            }
                        },
                        1: {
                            func: () => {
                                
                                gsap.timeline({})
                                .to("#I2", {opacity: 0})
                                .to("#I3", {opacity: 1})

                                gsap.timeline({repeat: -1})
                                .to("#Terminal10", {opacity: 1, duration: 1.5})
                                .to("#Terminal10", {opacity: 0, duration: 1})
                                .to("#Terminal11", {opacity: 1, duration: 1.5})
                                .to("#Terminal11", {opacity: 0, duration: 1})
                            }
                        },
                        2: {
                            func: () => {
                                gsap.killTweensOf("#Terminal10");
                                gsap.killTweensOf("#Terminal11");

                                gsap.timeline({})
                                .to("#I3", {opacity: 0})
                                .to("#I4", {opacity: 1})

                                gsap.timeline({})
                                .to("#Terminal10", {opacity: 0, pointerEvents: "none"})
                                .to("#Terminal11", {opacity: 0, pointerEvents: "none"})
                                .to("#SS2", {opacity: 1, position: "relative"}, "<")
                                .to("#ListItems2", {opacity: 1, position: "relative"}, "<")
                                .to("#SS3", {opacity: 1, position: "relative"}, "<")
                                .to("#ListItems3", {opacity: 1, position: "relative"}, "<")

                                gsap.timeline({repeat: -1, delay: 1})
                                .to("#Terminal12", {opacity: 1, duration: 1.5})
                                .to("#Terminal12", {opacity: 0, duration: 1})
                                .to("#Terminal13", {opacity: 1, duration: 1.5})
                                .to("#Terminal13", {opacity: 0, duration: 1})
                                .to("#Terminal14", {opacity: 1, duration: 1.5})
                                .to("#Terminal14", {opacity: 0, duration: 1})

                            }
                        },
                        3: {
                            func: () => {
                                setPhase(13)

                                gsap.timeline({})
                                .to("#I4", {opacity: 0})
                                .to("#I5", {opacity: 1})

                                gsap.killTweensOf("#Terminal12");
                                gsap.killTweensOf("#Terminal13");
                                gsap.killTweensOf("#Terminal14");

                                gsap.timeline({})
                                .to("#Terminal12", {opacity: 0, pointerEvents: "none"})
                                .to("#Terminal13", {opacity: 0, pointerEvents: "none"})
                                .to("#Terminal14", {opacity: 0, pointerEvents: "none"})
                            }
                        }
                    }
                }
            />
        ),
        Phase13: (
            <DemoContentSectionInRow 
            containerId={"FCH9"}
            header={"Establishing Value"}
            subText={PhaseSubText.Phase13}
            hasButton={true}
            onClick={() => {
                gsap.timeline({})
                .to("#I5", {opacity: 0})
                .to("#I6", {opacity: 1})

                Phase_Interactive_Basic({Id1: "#FCH9", Id2: "#FCH10"})
            }}/>
        ),
        Phase14: (
            <DemoContentSectionInRow 
            containerId={"FCH10"}
            header={"USDT Integration"}
            subText={PhaseSubText.Phase14}
            hasButton={true}
            onClick={() => {
                gsap.timeline({})
                .to("#I6", {opacity: 0})
                .to("#I7", {opacity: 1})
                Phase_Interactive_Basic({Id1: "#FCH10", Id2: "#FCH11"})
                setPhase(15)
            }}/>
        ),
        Phase15: (
            <DemoContentSectionInRow 
            containerId={"FCH11"}
            header={"Updating Permissions"}
            subText={PhaseSubText.Phase15}
            hasButton={true}
            onClick={() => {
                gsap.timeline({})
                .to("#I7", {opacity: 0})
                .to("#I8", {opacity: 1})
                Phase_Interactive_Basic({Id1: "#FCH11", Id2: "#FCH12"})
                gsap.to("#Terminal15", {opacity: 1, duration: 1.5})


                setPhase(16)
            }}/>
        ),
        Phase16: (
            <DemoContentSectionInRow 
            containerId={"FCH12"}
            header={"Funding Protocol"}
            subText={PhaseSubText.Phase16}
            hasButton={true}
            onClick={() => {

                gsap.timeline({})
                .to("#I8", {opacity: 0})
                .to("#I9", {opacity: 1})

                Phase_Interactive_Basic({Id1: "#FCH12", Id2: "#FCH13"})
                setPhase(17)

                gsap.to("#Terminal15", {opacity: 0, duration: 1.5})
            }}/>
        ),
        Phase17: (
            <DemoContentSectionInRow 
            containerId={"FCH13"}
            header={"Bob Creates an Offer"}
            subText={PhaseSubText.Phase17}
            hasButton={true}
            onClick={() => {
                gsap.timeline({})
                .to("#I9", {opacity: 0})
                .to("#I10", {opacity: 1})


                Phase_Interactive_Basic({Id1: "#FCH13", Id2: "#FCH14"})
            }}/>
        ),
        Phase18: (
            <DemoContentSectionInRow 
            containerId={"FCH14"}
            header={"Sally Accepts the Offer"}
            subText={PhaseSubText.Phase18}
            hasButton={true}
            onClick={() => {
                gsap.timeline({})
                .to("#I10", {opacity: 0})
                .to("#I11", {opacity: 1})

                Phase_Interactive_Basic({Id1: "#FCH14", Id2: "#FCH15"})
            }}/>
        ),
        Phase19: (
            <DemoContentSectionInRow 
            containerId={"FCH15"}
            header={"Conclusion"}
            subText={PhaseSubText.Phase19}
            />
        ),

    }
}
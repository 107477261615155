import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useRoutes
} from "react-router-dom";
import DocumentationPage from './components/DocumentationPage/DocumentationPage';
import TestSpace from './components/TestSpace/TestSpace';
import Experience from './components/Roadmap/Experience';
import { isDesktop, isMobile } from 'react-device-detect';

/*
        <Route path = "/get" element = {<InterfacePage />} />
        <Route path = "/docs" element = {<DocumentationPage />} />
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Router>
      <Routes>
        <Route path = "/" element = {<App airdropOpen={false}/>} />
        <Route path = "/get" element = {<span />} />
        <Route path = "/docs" element = {<DocumentationPage />} />
        <Route path = "/test-space" element = {<TestSpace />} />
        <Route path = "/airdrop" element = {<App airdropOpen = {true}/>} />
      </Routes>
    </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

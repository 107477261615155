import int from '../../../../CSS/SectionX_Interactive.module.css';
import styles from '../../../../CSS/SectionX.module.css';
import Demo from '../Demo/Demo.module.css';

export default function DemoContentSectionInRow({
    containerId,
    header,
    subText,
    hasButton,
    onClick,
})
{
    return(
        <div className = {`${int.FullCol} ${int.Hidden}`} id = {containerId}>
            <h2 
                className = {`${styles.Header4Revised}`}
            >
                {header}
            </h2>
            <p 
                className = {`${Demo.SubHeading} HN`} 
                style = {{marginTop: "0px", width: "80%"}}
                dangerouslySetInnerHTML={{__html: subText}}
            />
            {
                hasButton && (
                    <button 
                        onClick = {() => {onClick()}}
                        className = "glowingButton" 
                        style = {{marginTop: "0px", width: "30%"}}
                    >
                            Continue
                    </button>
                )
            }

        </div>
    )
}
import { Canvas, useThree } from "@react-three/fiber";
import styles from '../../CSS/SectionX.module.css';
import FallingParticles from "../../3D/FallingParticles";
import RocketTrail from "../../3D/Rocket";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export default function SectionXBg({sceneInView})
{

    return(
        <>
        <div className = {styles.MoonBg} id = "SectionX_Moon" />
            <Canvas 
            id = "SectionX_Canvas"
            style = {{position: "absolute", width: '100vw', height: "150vh"}}>
                <UpdateCameraPos />
                <FallingParticles count = {100} sceneInView = {sceneInView} />
                <RocketTrail sceneInView = {sceneInView} />
            </Canvas>
        </>
    )
}

const UpdateCameraPos = () => {
    const {camera} = useThree();

    useEffect(() => {
        gsap.to(camera.position, {
            z: -50,
            scrollTrigger: 
            {
                trigger: "#SectionX_ContentContainer", 
                start: "top -30%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -500%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll,
            },
        })
    }, [camera])


}
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from 'react'
import { MeshRefractionMaterial, useGLTF } from '@react-three/drei'
import { useFrame, useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three-stdlib';
import { gsap } from 'gsap';

export default function Ethlogo(props) {
    const r = useRef();

  const { nodes } = useGLTF('/ethlogo.glb');
   const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr')

    useFrame((_, delta) => {
        if(!r.current) return;

        r.current.rotation.y += delta;
    })

  const [mo1, setMo1] = useState(false);
  const [mo2, setMo2] = useState(false);

  const r1 = useRef();
  const r2 = useRef();

  useEffect(() => {
    if(mo1) {
      gsap.killTweensOf(r1.current);
      gsap.to(r1.current.material.color, 
        {
          r: 0.314,
          g: 0.314,
          b: 0.314,
          duration: 1
        }
      )
      return;
    }

    gsap.killTweensOf(r1.current);
    gsap.to(r1.current.material.color, {
      r: 1,
      g: 1,
      b: 1,
      duration: 1,
    });
  }, [mo1]);

  useEffect(() => {
    if(mo2) {
      gsap.killTweensOf(r2.current);
      gsap.to(r2.current.material.color, 
        {
          r: 0.314,
          g: 0.314,
          b: 0.314,
          duration: 1
        }
      )
      return;
    }

    gsap.killTweensOf(r2.current);
    gsap.to(r2.current.material.color, {
      r: 1,
      g: 1,
      b: 1,
      duration: 1
    });
    

  }, [mo2]);

  return (
    <group {...props} dispose={null} ref = {r}>
      <mesh
        ref = {r1}
        onPointerOver={() => {setMo1(true)}}
        onPointerOut={() => {setMo1(false)}}
        castShadow
        receiveShadow
        geometry={nodes.upper_part.geometry}
        position={[0.13, 2.709, 0.177]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.002}
      >
        <MeshRefractionMaterial 
            envMap={texture} 
            toneMapped = {false} 
            color = "white" 
            bounces = {3} 
            fresnel = {0} 
            ior = {2.4}
            aberrationStrength = {0.1} />
      </mesh>
      <mesh
        ref={r2}
        onPointerEnter={() => {setMo2(true)}}
        onPointerLeave={() => {setMo2(false)}}
        castShadow
        receiveShadow
        geometry={nodes.lower_part.geometry}
        position={[0.13, 2.709, 0.177]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.002}
      >
            <MeshRefractionMaterial 
                envMap={texture} 
                toneMapped = {false} 
                color = "white" 
                bounces = {3} 
                fresnel = {0} 
                ior = {2.4}
                aberrationStrength = {0.1} />

      </mesh>
    </group>
  )
}

useGLTF.preload('/ethlogo.glb')
import { useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { InstancedMesh, Object3D, Vector3 } from "three";


const MAX_SPEED = .5;

export default function FallingParticles(props)
{
    const ref = useRef();
    const dummy = useMemo(() => new Object3D(), []);
    const speeds = useMemo(() => {

        let allSpeeds = [];

        for(let i = 0; i < props.count; i++)
        {
            allSpeeds.push(Math.random() * MAX_SPEED);
        }

        return allSpeeds;

    }, [])

    const positions = useMemo(() => {
        const allPositions = [];
        for (let i = 0; i < props.count; i++) {
            const posX = (Math.random() * 800) - 400; // Centered around 0 (-200 to 200)
            const posY = Math.random() * 400; // Y between 0 and 400
            const posZ = Math.random() * -400; // Z between 0 and -400
            allPositions.push([posX, posY, posZ]);
        }
        return allPositions;
    }, [props.count]);

    useEffect(() => {
        for (let i = 0; i < props.count; i++) {
            const [x, y, z] = positions[i];
            dummy.position.set(x, y, z);
            dummy.updateMatrix();
            ref.current.setMatrixAt(i, dummy.matrix); // Set initial matrix
        }
        ref.current.instanceMatrix.needsUpdate = true; // Notify Three.js about the change
    }, [dummy, positions, props.count]);

    useFrame(() => {
        for (let i = 0; i < props.count; i++) {
            const [x, y, z] = positions[i];
            let newY = y + speeds[i]; // Decrease Y position by its speed

            // Reset position when it reaches 0
            if (newY >= 400) {
                newY = 0; // Reset to top
            }

            // Update position and matrix
            positions[i][1] = newY; // Update the stored position
            dummy.position.set(x, newY, z);
            dummy.updateMatrix();
            ref.current.setMatrixAt(i, dummy.matrix); // Update matrix for this instance
        }
        ref.current.instanceMatrix.needsUpdate = true; // Notify Three.js about the update
    });

    
    return(
        <instancedMesh 
            position = {[0,-200, -100]}
            ref = {ref}
            args = {[null, null, props.count * 3]}
        >
            <sphereGeometry args = {[0.4,1]} />
            <meshBasicMaterial color = "grey"/>
        </instancedMesh>
    )
}
import { useRef, useMemo, useState } from "react";
import { CatmullRomCurve3, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { Trail } from "@react-three/drei";
import { useTexture } from "@react-three/drei";
import rocket from '../../../../assets/rocket.svg'

export default function RocketTrail() {
  const rocketRef = useRef();
  const trailRef = useRef();

  const loadedTex = useTexture(rocket);
  const [trailEnabled, setTrailEnabled] = useState(true)

  const curve = useMemo(() => {
    if(!window) return;

    const curve1 = new CatmullRomCurve3([
        new Vector3(150, -50, -200),
        new Vector3(130, 0, -200),
        new Vector3(110, 50, -200),
        new Vector3(90, 100, -200),
        new Vector3(70, 200, -200),
    ]);

    const curveThinScreen = new CatmullRomCurve3([
        new Vector3(100, -50, -200),
        new Vector3(80, 0, -200),
        new Vector3(60, 50, -200),
        new Vector3(40, 100, -200),
        new Vector3(20, 200, -200),
    ]);

    return (window.innerWidth < 1400) ? curveThinScreen : curve1;

  }, []);

  const progress = useRef(0);

  useFrame((_, delta) => {
    progress.current += delta * 0.0055;


    if(progress.current >= 1)
    {
      if(trailEnabled) setTrailEnabled(false)
        progress.current = 0;
    }
    else
    {
      setTrailEnabled(true);
    }

    let sanitizedProgress = progress.current;

    if(sanitizedProgress > 1) sanitizedProgress = 1;
    else if(sanitizedProgress < 0) sanitizedProgress = 0;
    else sanitizedProgress = progress.current;

    const position = curve.getPointAt(sanitizedProgress);
    rocketRef.current.position.copy(position);
  });

  if(trailEnabled === false)
  {
    return(
      <mesh ref={rocketRef} rotation = {[-.25, 0, 0]}>
      <planeGeometry args={[20, 20]} />
      <meshBasicMaterial
        map={loadedTex}
        transparent={true}
      />
    </mesh>
    )
  }

  return (
    <>
      <Trail
        ref={trailRef}
        width={20}
        length={300}
        color="#ffb2a1"
        attenuation={(t) => t * t}
      >
        <mesh ref={rocketRef} rotation = {[-.25, 0, 0]}>
          <planeGeometry args={[20, 20]} />
          <meshBasicMaterial
            map={loadedTex}
            transparent={true}
          />
        </mesh>
      </Trail>
    </>
  );
}

import styles from './SidebarItem.module.css';
import '../../../../css/keyFonts.css';
import pageMarker from '../../../../assets/pageMarker.svg';

export default function SidebarItem({page, label, pageNumber, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return(
        <>
            <div className = {styles.Container} onClick = {() => {
                setPage(pageNumber)

                if(mobileSidebarOpen) setMobileSidebarOpen(false);
            }}>
                <p className={`${styles.Heading} HN`}>{label}</p>
                {page === pageNumber && <img src = {pageMarker} />}
            </div>
        </>
    )
}
import int from '../../CSS/SectionX_Interactive.module.css';
import Logic from '../../../../../assets/Logic.svg';
import HBContract from '../../../../../assets/HBContract.svg';
import Bob from '../../../../../assets/Bob.svg';
import { HideOnly, HideOnlyAbs, RevealOnly, StorageSpaces1, StorageSpacesSwap } from '../../Animations/SectionX_Animations';
import gsap from 'gsap';

export default function SubSection3ImageSpace()
{
    return(
        <div className = {int.ImageContainer} id = "SectionX_ImageContainer">
            <div id = "SectionX_ContractWrapper" className = {int.ContractWrapper}>
                <div id = "ContractTextWrapper" className = {int.ContractTextWrapper}>
                    <p 
                        id = "SimpleText1" 
                        className = {`${int.SimpleText} HN`}
                        >update_permissions(target, level)</p>
                    <p 
                        id = "SimpleText2" 
                        className = {`${int.SimpleText2} HN`}
                        >init_create(types, sizes)</p>
                    <p 
                        id = "SimpleText3" 
                        className = {`${int.SimpleText2} HN`}
                        >push(amount, storageSpace)</p>
                    <p 
                        id = "SimpleText4" 
                        className = {`${int.SimpleText2} HN`}
                        >insert_new_member(valType, storageSpace)</p>
                    <p 
                        id = "SimpleText5" 
                        className = {`${int.SimpleText2} HN`}
                        >put(amount, memberIndex, entryIndex, storageSpace)</p>
                </div>
                <img 
                    id = "SectionX_ContractImg" 
                    src = {HBContract} 
                    className = {int.HBC}
                />
            </div>
            
            <div 
                id = "SectionX_LogicContainer"
                className = {`${int.LogicContainer} ${int.LogicShift}`}>
                <p 
                    id = "SectionX_ContractTitle"
                    className = "HN" 
                    style = {{textAlign: "center", fontSize: "12px", margin: 0, width: "100px", textAlign: 'center'}}
                ><b style = {{color: 'rgb(160, 204, 240)'}}>Token</b></p>
                <img 
                    id = "SectionX_LogicImage" 
                    src = {Logic} 
                    className = {int.Logic}
                />
            </div>

            <div 
                className = {int.LogicContainer}
                id = "SectionX_LogicContainer2"
            >
                <p 
                    id = "SectionX_ContractTitle"
                    className = "HN" 
                    style = {{fontSize: "12px", margin: 0, width: "100px", textAlign: 'center'}}
                ><b style = {{color: 'rgb(160, 240, 172)'}}>Market</b></p>
                <img 
                    id = "SectionX_LogicImage" 
                    src = {Logic} 
                    className = {`${int.Logic} ${int.LogicFlip}`}
                />
            </div>

            <div 
                className = {int.BobContainer}
                id = "SectionX_Bob"
            >
                <img 
                    id = "SectionX_BobImg" 
                    src = {Bob} 
                    className = {`${int.Bob}`}
                />
            </div>

            <div id = "ExplanationContainer" className = {int.ExplanationContainer}>
                
                <div className = {int.TextContainer} id = "SectionX_TextContainer" >
                    <h2 id = "T1" className = {int.Title}>
                        Understanding Storage Spaces
                    </h2>
                    <p id = "BT1" className = {`${int.BasicText} HN`}>
                        Storage spaces define a single extendible storage structure.  
                        To create a storage space, we need to pass 
                        in an array of types and sizes.
                    </p>

                    <button 
                        id = "BB1" 
                        className = {int.Button}
                        onClick = {() => {
                            StorageSpacesSwap({
                                idButton1: "#BB1",
                                idButton2: "#BB2",
                                idPrevContainer: "#SectionX_TextContainer",
                                idCurrentContainer: "#SectionX_TextContainer2"
                            })
                            RevealOnly({id: "#FCH0", delay: 1});
                            RevealOnly({id: "#FCH1", delay: 2})
                        }}
                        >Next</button>
                </div>
                <div className = {int.TextContainer2} id = "SectionX_TextContainer2">
                    <h2 id = "T2" className = {int.Title}>
                        Storage Space Arguments
                    </h2>
                    <p id = "BT2" className = {`${int.BasicText} HN`}>
                        Let's create a storage space for users.  
                        We'll give each user an ID (<i>uint:</i> type 1) 
                        and a name (<i>string:</i> type 6)
                    </p>

                    <button 
                        id = "BB2" 
                        className = {int.Button}
                        onClick = {() => {
                            StorageSpacesSwap({
                                idButton1: "#BB2",
                                idButton2: "#BB3",
                                idPrevContainer: "#SectionX_TextContainer2",
                                idCurrentContainer: "#SectionX_TextContainer3"
                            });
                            HideOnly({id: "#FCH1"});
                            HideOnlyAbs({id: "#FCH1"})
                            RevealOnly({id: "#FCH2", delay: 1})
                            
                        }}
                        >Next</button>
                </div>
                <div className = {int.TextContainer2} id = "SectionX_TextContainer3">
                    <h2 id = "T3" className = {int.Title}>
                        Using init_create()
                    </h2>
                    <p id = "BT3" className = {`${int.BasicText} HN`}>
                        To create our storage structure, we call the function <i>init_create </i> 
                        with the following arguments: 
                        <br/><br/>
                        <i>init_create(types: [1, 6], sizes: [256, 0])</i>
                    </p>

                    <button 
                        id = "BB3" 
                        className = {int.Button}
                        onClick = {() => {StorageSpacesSwap({
                                idButton1: "#BB3",
                                idButton2: "#BB4",
                                idPrevContainer: "#SectionX_TextContainer3",
                                idCurrentContainer: "#SectionX_TextContainer4"
                            })
                            HideOnly({id: "#FCH2"});
                            HideOnlyAbs({id: "#FCH2"})
                            gsap.to("#StorageContainer", {opacity: 1, pointerEvents: "all"})
                            RevealOnly({id: "#StorageItems", delay: 1})
                            RevealOnly({id: "#FCH3", delay: 2})
                            gsap.to("#Terminal1", {opacity: 1})
                        }}
                        >Call init_create()</button>
                </div>
                <div className = {int.TextContainer2} id = "SectionX_TextContainer4">
                    <h2 id = "T4" className = {int.Title}>
                        Results
                    </h2>
                    <p id = "BT4" className = {`${int.BasicText} HN`}>
                        Storage space 0 now stores the <i>user </i> 
                        data structure!  In high-level Solidity, 
                        it would look like: 

                        <br/><br/>
                        <span>{`struct user {
                            uint256 id;
                            string name;
                        }
                                
                        `}
                        </span>
                    </p>
                </div>
            </div>
        </div>

    )
}                    
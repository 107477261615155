import int from '../../../../CSS/SectionX_Interactive.module.css';
import styles from '../../../../CSS/SectionX.module.css';
import { useEffect, useState } from 'react';
import gsap from 'gsap';

export default function LongContentSection({
    firstSubtext,
    firstHeading,
    hiddenSubtexts,
    hiddenHeadings,
    setComplete,
    hiddenIdPrefix,
    containerId,
    headerId,
    functions,
})
{
    const [section, setSection] = useState(0);
    const [heading, setHeading] = useState(firstHeading);
    const TOTAL_SECTIONS = hiddenSubtexts.length + 1;

    useEffect(() => {
        if(section === TOTAL_SECTIONS)
        {
            setComplete(true);
        }

        if(section == 0 && TOTAL_SECTIONS !== 0) return;
        else
        {
            let prevSection = section - 1;
            let prevId = `${hiddenIdPrefix}${prevSection}`;
            let currentId = `${hiddenIdPrefix}${section}`;

            gsap.killTweensOf(`#${prevId}`);
            gsap.killTweensOf(`#${currentId}`);

            gsap.timeline({})
            .to(`#${prevId}`, {opacity: 0, pointerEvents: "none"})
            .to(`#${currentId}`, {opacity: 1, pointerEvents: "all"});

            setHeading(hiddenHeadings[section - 1]);
        }


    }, [section])

    const HandleTransition = () => {
        let oldSection = section;
        setSection(section + 1);
        if(functions[oldSection] != undefined)
        {
            functions[oldSection].func();
        }
    }

    const hiddenSections = hiddenSubtexts.map((subtext, index) => {
        return(
            <p 
            key = {`${hiddenIdPrefix}${index + 1}`}
            id = {`${hiddenIdPrefix}${index + 1}`}
            className = {`${styles.Header4Sub} ${int.HiddenAbs} HN`} 
            style = {{marginTop: "0px", width: "80%"}}
            dangerouslySetInnerHTML={{__html: subtext}} />
        )
    })

    return(
        <div className = {`${int.FullCol} ${int.Hidden}`} id = {containerId}>
            <h2 id = {headerId} className = {`${styles.Header4Revised}`} 
                dangerouslySetInnerHTML={{__html: heading}}>
            </h2>

            <p className = {`${styles.Header4Sub} HN`} 
            id = {`${hiddenIdPrefix}0`}
            dangerouslySetInnerHTML={{__html: firstSubtext}}
            style = {{marginTop: "0px", marginBottom: "0px", padding: 0, width: "80%"}} />

            {hiddenSections}

            <button 
                onClick = {() => {HandleTransition()}}
                className = "glowingButton" 
                style = {{ width: "30%", marginTop: "20px"}}
            >
                    Continue
            </button>

        </div>
    )
}
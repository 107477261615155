import PageLayout from "../Common/PageLayout";
import pageStyles from '../Common/page.module.css';

export default function Page_6_2({mobileMode})
{
    return(
        <PageLayout mobileMode = {mobileMode}>
            <div style = {{height: '80vh', width: '100%'}}>
                <div
                    className={pageStyles.IFrameContainer}
                >
                    <iframe
                        style={{
                            width: "100%",
                            maxWidth: "560px",
                            height: "100%",
                            borderRadius: "10px",
                            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                        }}
                        src="https://www.youtube.com/embed/LngHy8ixdvs?si=QGPEUKgvylXLAykm"
                    ></iframe>
                </div>
            </div>

        </PageLayout>
    )
}
import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import InsInit from '../../../../assets/InsInit.svg';
import Up from '../../../../assets/UpgradeProcess.svg';
import Audius from '../../../../assets/AudiusHack.png';
import styles from '../Common/page.module.css';


export default function Page_4_2({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>

            <h2 className={styles.PageHeader}>
                Making Cross-Contract Interoperability More Convenient, 
                Efficient, and Robust.
            </h2>


            <H>Overview</H>

        </PageLayout>
        
    )
}
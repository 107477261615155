import styles from './ImageSpace2.module.css';
import Logic from '../../../../../assets/Logic.svg';
import HBContract from '../../../../../assets/HBContract.svg';
import Bob from '../../../../../assets/Bob.svg';
import { HideOnly, HideOnlyAbs, RevealOnly, StorageSpaces1, StorageSpacesSwap } from '../../Animations/SectionX_Animations';
import gsap from 'gsap';

import I1 from '../../../../../assets/I1.svg';
import I2 from '../../../../../assets/I2.svg';
import I3 from '../../../../../assets/I3.svg';
import I4 from '../../../../../assets/I4.svg';
import I5 from '../../../../../assets/I5.svg';
import I6 from '../../../../../assets/I6.svg';
import I7 from '../../../../../assets/I7.svg';
import I8 from '../../../../../assets/I8.svg';
import I9 from '../../../../../assets/I9.svg';
import I10 from '../../../../../assets/I10.svg';
import I11 from '../../../../../assets/I11.svg';


export default function SubSection3ImageSpace2()
{
    return(
        <div className = {styles.ImageContainer} id = "SectionX_ImageContainer2">

            <img className = {styles.InnerImg} id = "I1" src = {I1}/>
            <img className = {styles.InnerImg} id = "I2" src = {I2}/>
            <img className = {styles.InnerImg} id = "I3" src = {I3}/>
            <img className = {styles.InnerImg} id = "I4" src = {I4}/>
            <img className = {styles.InnerImg} id = "I5" src = {I5}/>
            <img className = {styles.InnerImg} id = "I6" src = {I6}/>
            <img className = {styles.InnerImg} id = "I7" src = {I7}/>
            <img className = {styles.InnerImg} id = "I8" src = {I8}/>
            <img className = {styles.InnerImg} id = "I9" src = {I9}/>
            <img className = {styles.InnerImg} id = "I10" src = {I10}/>
            <img className = {styles.InnerImg} id = "I11" src = {I11}/>

        </div>

    )
}                    
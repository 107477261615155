import '../../../../css/keyFonts.css';
import pageStyles from '../Common/page.module.css';
import {P, H, Tab, Ul, Li, H2, S, P2} from '../Common/CommonElements'

import Interoperability from '../../../../assets/interoperability2.svg';
import Shared from '../../../../assets/sharedAccess.svg';

const BPSection = ({title, section}) => {
    return( 
        <div style = {{marginLeft: "20px"}}>
            <b>
                {title} -
            </b>  
            <P2>
                &nbsp;
                {section}
            </P2>
            <S />
        </div>
    )
}

export default function CoreAdvantages({mobileMode})
{
    return (
        <>

            <H>Core Advantages</H>
            <Ul>
                <div>
                    <Li>
                        <H2>In-Place Storage Upgrades Without Slot Conflicts</H2>
                        <P>
                            <Tab />
                            Smart contract storage structures are traditionally difficult to modify in a
                            production system—developers must either allocate extra space in advance or deploy
                            new contracts when upgrades are needed, both of which add cost, new risks, and complexity.
                            HoneyBadger introduces in-place storage expansion, allowing new fields to be added
                            without breaking compatibility, requiring migrations, or disrupting existing data structures.
                            This makes upgrades simpler and more cost-effective, allowing protocols to extend
                            functionality without major cost, effort, or risk.
                        </P>

                        {
                            <BPSection
                                title="Extendible Structs"
                                section={`
                                Storage structures can dynamically evolve post-deployment, 
                                allowing new members to be appended without corrupting existing slot 
                                assignments or breaking ABI expectations.
                                `}
                            />
                        }

                        {
                            <BPSection
                                title="Deterministic Hash-Based Indexing"
                                section={`
                                Each struct field is derived via a deterministic keccak256 hashing mechanism,
                                ensuring collision-free expansions, preventing unintended overwrites, 
                                and eliminating the need for manual slot reservations.
                                `}
                            />
                        }

                        {
                            <BPSection
                                title="No Storage Migrations or Storage-Delegate Redeployments"
                                section={`
                                Structural modifications are handled in-place, avoiding contract redeployments,
                                reducing governance friction, and ensuring seamless upgradeability.
                                `}
                            />
                        }

                        {
                            <BPSection
                                title="Immutable Base, Expandable Fields"
                                section={`
                                The established struct layout within a storage space is immutable; new fields are appended 
                                sequentially, ensuring that older contracts and interfaces remain functional.
                                `}
                            />
                        }

                        {
                            <BPSection
                                title="Efficient Storage Packing"
                                section={`
                                Bitwise packing strategies minimize padding waste, allowing for dense, 
                                gas-efficient storage layouts even when structs are extended.
                                `}
                            />
                        }
                    </Li>
                </div>

                <>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                    <div className={pageStyles.Center}>
                        <img 
                            src={Interoperability} 
                            className={pageStyles.Img}
                        />
                    </div>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                </>

                <Li>
                    <H2>Seamless Cross-Contract Storage & Interoperability</H2>
                    {/* 
                        Removed repeated text that was nearly identical to the In-Place 
                        Storage Upgrades intro. Replaced with a more direct statement 
                        about cross-contract synergy. 
                    */}
                    <P>
                        <Tab />
                        Interoperability in Solidity contracts is often constrained by 
                        fragmented storage models, where each contract maintains its own 
                        mappings, requiring explicit integration logic, redundant state 
                        duplication, or complex adapter layers to synchronize data. 
                        HoneyBadger resolves this by providing a structured, permissioned 
                        storage layer that multiple contracts can access directly through 
                        a standardized interface for both <i>put </i> and <i>get </i> operations, eliminating the 
                        need for custom getters, adapters, or manual slot tracking. By 
                        enforcing deterministic indexing and single-hop retrieval, HoneyBadger 
                        ensures that evolving smart contract ecosystems can share state 
                        efficiently, reducing gas costs, simplifying upgrades, and removing
                        integration overhead.
                    </P>

                    <>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                    <div className={pageStyles.Center}>
                        <img 
                            src={Shared} 
                            className={pageStyles.Img}
                        />
                    </div>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                </>

                    {
                        <BPSection
                            title="Unified Storage Layer"
                            section={`
                            Consolidates data across multiple contracts into a single, structured 
                            storage space, preventing fragmentation and redundant state duplications.
                            `}
                        />
                    }

                    {
                        <BPSection
                            title="Standardized Storage API"
                            section={`
                            Functions including get(), put(), and insert_new_member() provide uniform, 
                            predictable access and modification methods across all integrated contracts.
                            `}
                        />
                    }

                    {
                        <BPSection
                            title="Dynamic Mapped-Struct Definitions Across Contracts"
                            section={`
                            Contracts referencing a shared storage space automatically 
                            inherit schema expansions, preventing version mismatches 
                            and reducing re-integration costs.
                            `}
                        />
                    }

                    {
                        <BPSection
                            title="Logical Namespace Isolation"
                            section={`
                            Storage spaces are logically segmented to prevent unintended cross-contract 
                            interference while preserving modularity and composability.
                            `}
                        />
                    }

                    {
                        <BPSection
                            title="Permissioned Interactions"
                            section={`
                            Read and write access is strictly managed per contract/module, 
                            preventing unauthorized modifications and ensuring storage integrity 
                            across shared systems.
                            `}
                        />
                    }
                </Li>

                <Li>
                    <H2>Single-Hop Storage Access</H2>
                    <P>
                        <Tab />
                        In many systems, multiple-stop cross-contract calls or 
                        proxy-delegate chains add gas overhead and complicate upgrades. By consolidating 
                        data into one layer, HoneyBadger enables direct reads and writes, reducing
                        unnecessary external calls or copies. This lowers long-term operational costs
                        and simplifies system maintenance as contracts evolve.
                    </P>

                    {/*
                        Revised bullet: "Eliminates Proxy-Delegate Chains" -> "Reduces Proxy-Delegate Overhead".
                        Toned down "ensuring O(1)" to "providing direct lookups".
                    */}

                    <BPSection 
                        title="Reduces Proxy-Delegate Overhead"
                        section={`
                        Direct storage interactions lessen the need for delegatecall-based proxy execution, 
                        helping to lower execution overhead and potential re-entrancy risks.
                        `}
                    />

                    <BPSection 
                        title="Optimized Gas for Reads/Writes"
                        section={`
                        Storage access occurs in a single execution layer, avoiding multi-hop resolution 
                        costs typical in large-scale ecosystems.
                        `}
                    />

                    <BPSection 
                        title="Compact Struct Indexing"
                        section={`
                        Bitwise-aligned struct definitions reduce misaligned offsets, cutting gas overhead 
                        for indexed access patterns.
                        `}
                    />

                    <BPSection 
                        title="Efficient Storage Resolution Pathways"
                        section={`
                        Lookups use deterministic hashed offsets, providing direct slot references with 
                        minimal overhead rather than layering multiple calls.
                        `}
                    />

                    <BPSection 
                        title="Batch Storage Operations"
                        section={`
                        Supports multi-field updates and retrievals in a single transaction, lowering 
                        redundant storage interactions and improving execution efficiency.
                        `}
                    />
                </Li>

                <Li>
                    <H2>Permissioned Multi-Module Access</H2>
                    <P>
                        <Tab />
                        When multiple contracts share storage, defining clear access 
                        controls is crucial to prevent unauthorized operations, 
                        data inconsistencies, and governance conflicts. HoneyBadger enforces 
                        role-based access control (RBAC) at both the contract and function levels, 
                        allowing developers to specify who can read, write, or modify stored data.
                        Permissions can be dynamically adjusted through smart contract logic or 
                        decentralized governance, ensuring that protocols maintain both security 
                        and adaptability as they scale.
                    </P>

                    <BPSection 
                        title="Role-Based Access Control (RBAC)"
                        section={`
                            Per-contract and per-function access tiers define exactly 
                            who can modify or read specific storage fields, preventing 
                            unauthorized state alterations.
                        `}
                    />

                    <BPSection 
                        title="Granular Read/Write Segmentation"
                        section={`
                            Developers can enforce fine-grained permission policies, 
                            restricting access to individual struct members or entire storage spaces.
                        `}
                    />

                    {/*
                        Removed exclamation from "governance modules!"
                    */}
                    <BPSection 
                        title="Dynamic Authorization Logic"
                        section={`
                            Permission configurations can be variable or constant, 
                            and can be managed through governance modules or automated 
                            contract logic.
                        `}
                    />

                    <BPSection 
                        title="Whitelisted Contract Calls"
                        section={`
                            Contracts must be explicitly registered to interact with 
                            shared storage, ensuring external integrations don’t introduce 
                            attack vectors.
                        `}
                    />

                    <BPSection 
                        title="Selective User-Level Restrictions"
                        section={`
                            User interactions can be restricted to specific data subsets, 
                            allowing differentiated access control across multiple roles.
                        `}
                    />
                </Li>

                <Li>
                    <H2>Composable for Large Ecosystems</H2>

                    <P>
                        <Tab/>
                        Many high-level DeFi or DAO deployments include multiple protocols, 
                        each requiring access to overlapping data structures. In a typical setup, 
                        developers might maintain separate mappings for each protocol or rely on 
                        custom bridging logic to coordinate state changes. HoneyBadger enhances the status quo 
                        by providing a consistent storage interface and isolating each protocol’s 
                        data into logically distinct spaces. This arrangement allows every module to 
                        evolve—adding new fields or contracts—without the need for manual slot tracking 
                        or elaborate synchronization. By supporting batch operations and minimizing 
                        redundant calls, HoneyBadger helps large systems sustain performance even when 
                        they continuously expand with new features.
                    </P>

                    <BPSection 
                        title="Multi-Contract Storage Compatibility"
                        section={`
                            Multiple contracts can query and modify the same structured data 
                            without redundant storage declarations or state duplication.
                        `}
                    />

                    {/*
                        Reword "ensuring future-proof composability" -> "facilitating flexible composability".
                    */}
                    <BPSection 
                        title="Universal Storage Call Pattern"
                        section={`
                            Every contract interacts with storage through a standardized, extendible interface, 
                            facilitating flexible composability as protocols expand.
                        `}
                    />

                    <BPSection 
                        title="Multi-Tenant Storage Management"
                        section={`
                            Each protocol or contract suite operates within isolated storage spaces, 
                            preventing conflicts while allowing controlled cross-system interactions.
                        `}
                    />

                    <BPSection 
                        title="No Hardcoded Slot Assignments"
                        section={`
                            Eliminates dependency on manual slot tracking, allowing fully dynamic schema 
                            expansion without integration-breaking modifications.
                        `}
                    />

                    {/*
                        Replaced "ensuring performance scales" with a more direct phrase
                    */}
                    <BPSection 
                        title="Optimized for High-Throughput Applications"
                        section={`
                            Gas-efficient struct encoding, batch updates, and single-hop indexing 
                            help maintain performance in DeFi, DAO, and other modular protocol environments.
                        `}
                    />
                </Li>
            </Ul>
        </>
    )
}
import gsap from "gsap";

export default function Create_SectionX_Animations()
{
    gsap.timeline({}).fromTo(
        "#SectionX_Container",
        { opacity: 0},
        {
            opacity: 1, // Animate to fully visible
            scrollTrigger: {
                trigger: "#SectionX_Container", // Use the container itself as the trigger
                start: "top 10%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -20%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll
            }
        }
    )

    gsap.timeline({}).to(
        "#SectionX_Moon",
        {
            opacity: 1,
            backgroundImage: "radial-gradient(circle at top, #282828 3%, rgb(18, 18, 18) 20%, #0e0e0f 25%)",
            scrollTrigger: {
                trigger: "#SectionX_Moon", // Use the container itself as the trigger
                start: "top 10%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -20%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll
            }
        },
    ).to(
        "#SectionX_Moon",
        {
            scrollTrigger: {
                trigger: "#SectionX_Moon", // Use the container itself as the trigger
                start: "top -20%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -500%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll,
                pin: true,
            }
        },
    )
    gsap.timeline({}).to(
        "#SectionX_ContentContainer",
        {
            opacity: 1,
            scrollTrigger: 
            {
                trigger: "#SectionX_ContentContainer", 
                start: "top 40%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -30%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll,
            },
        }
    ).to(
        "#SectionX_ContentContainer",
        {
            scrollTrigger: 
            {
                trigger: "#SectionX_ContentContainer", 
                start: "top -30%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -500%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll,
                pin: true,
            },
        }
    )

    gsap.to('#SectionX_Canvas',
        {
            scrollTrigger: {
                trigger: "#SectionX_Canvas", 
                start: "top -20%", // Trigger when the top of the container hits 80% of the viewport height
                end: "top -500%", // End the animation when the top of the container hits 20%
                scrub: true, // Smoothly synchronize animation with scroll,
                pin: true,
            },
        }
    )

    gsap.timeline({
        scrollTrigger: {
            trigger: "#SectionX_ContentContainer", 
            start: "top -20%",
            end: "top -450%",
            scrub: true,
        }
    })
    .fromTo('#SectionX_TwoItemsContainer',
        { y: 200, opacity: 0 }, // Starting position and opacity
        { y: 0, opacity: 1, duration: 1 } // Animate to final position and opacity
    )
    .to("#SectionX_TwoItemsContainer", {
        duration: 4
    })
    .to('#SectionX_TwoItemsContainer',
        { opacity: 0, duration: 10} // Fade out
    )
    .to("#SectionX_Header", 
        {opacity: 0, duration: 10}, "<"
    )
    .to("#SectionX_TwoItemsContainer", {pointerEvents: "none"})
    .to("#SectionX_Section2Container", {
        opacity: 1, duration: 10
    })
    .to("SectionX_Section2Container", {duration: 10})
    .to("#SectionX_Section2Container", {
        opacity: 0, duration: 10
    })
    .to("#SectionX_InteractiveContainer", {
        opacity: 1, duration: 10
    })
    .to("#SectionX_InteractiveContainer", {
        duration: 10
    })
    .to("#SectionX_InteractiveContainer", {
        opacity: 0, duration: 10
    });

}

export function Intro_Interactive()
{
    gsap.timeline({}).to("#SectionX_IntroWrapper", {
        opacity: 0, 
        duration: 0.5,
    })
    .to("#SectionX_IntroWrapper", {top: "70%"})
    .to("#SectionX_ImageContainer", {height: "40%", 
            borderBottom: "1px solid rgb(85, 85, 85)", duration: 0.5})
    .to("#SectionX_IntroWrapper", {pointerEvents: "none"}, "<")
    .to("#SectionX_ContractImg", {opacity: 1, duration: 0.5})
    .to("#SectionX_IntroWrapper2", {
        opacity: 1, 
        duration: 0.5,
        pointerEvents: "all"
    })
}

export function Phase2_Interactive()
{
    gsap.timeline({})
    .to("#SectionX_IntroWrapper2", {
        opacity: 0, 
        duration: 0.5,
        pointerEvents: 'none'
    })
    .to("#SectionX_LogicContainer", 
        {
            opacity: 1,
            duration: .5,   
        }
    )
    .to("#SectionX_LogicContainer2", 
        {
            opacity: 1,
            duration: .5,   
        }, "<"
    )
    .to("#SectionX_IntroWrapper3", {
        opacity: 1, 
        duration: 0.5,
        pointerEvents: 'all'
    })
}

export const Phase3_Interactive = () => {
    gsap.timeline({})
    .to("#SectionX_IntroWrapper3", {
        opacity: 0, 
        duration: 0.5,
        pointerEvents: 'none'
    })
    .to("#ContractTextWrapper",
        {
            opacity: 1,
            duration: 0.5,
        }
    )
    .to("#ContractTextWrapper",
        {
            duration: 1,
        }
    )
    .to("#SectionX_IntroWrapper4", {
        pointerEvents: 'all',
        opacity: 1,
        duration: 0.5,
    })
}

export const Phase4_Interactive = () =>
{
    gsap.timeline({})
    .to("#SectionX_IntroWrapper4", {
        pointerEvents: 'none',
        opacity: 0,
        duration: 0.5,
    })
    .to("#SimpleText1", {
        opacity: 0,
        duration: 0.5,
    }, "<")
    .to("#SimpleText2", {
        opacity: 0,
        duration: 0.5,
    }, "<")
    .to("#SectionX_LogicContainer", {
        left: "65%",
        duration: .5,
    })
    .to("#SectionX_LogicContainer2", {
        left: "35%",
        duration: .5,
    })
    .to("#SectionX_LogicContainer2", {
        duration: 1,
    })
    .to("#SectionX_LogicContainer", {
        left: "45%",
        duration: .5,
    }, "<")
    .to("#SectionX_LogicContainer2", {
        left: "15%",
        duration: .5,
    }, "<")
    .to("#SectionX_ContractWrapper", 
        {
            left: "30%",
            duration: .5,
        },
        "<"
    )
    .to("#ListItems", {
        opacity: 1
    })
    .to("#ExplanationContainer", 
        {
            opacity: 1,
            pointerEvents: 'all'
        }
    )
    .to("#SectionX_IntroWrapper6", {
        opacity: 1,
        pointerEvents: 'all',
        duration: 0.5,
    })
}

export const Phase5_Interactive = () => {
    gsap.timeline({})
    .to("#SectionX_TextContainer4", {opacity: 0, pointerEvents: "none"})
    .to("#FCH3", {opacity: 0, pointerEvents: "none"})
    .to("#ExplanationContainer", 
        {
            opacity: 0,
            pointerEvents: 'none'
        }, "<"
    )
    .to("#FCH3", {position: "absolute"})
    .to("#SectionX_LogicContainer", {
        left: "80%",
        duration: .5,
    }, "<")
    .to("#SimpleText2", {opacity: 1})
    .to("#SectionX_LogicContainer2", {
        left: "20%",
        duration: .5,
    }, "<")
    .to("#SectionX_ContractWrapper", {
        left: "50%",
        duration: .5,
    }, "<")
    .to("#Terminal1", {opacity: 0})
    .to("#Terminal2", {opacity: 1})
    .to("#FCH4", {opacity: 1, pointerEvents: 'all'})
}

export const Phase6_Interactive = () => {

    gsap.timeline({})
    .to("#FCH4", {opacity: 0, pointerEvents: "none"})
    .to("#FCH4", {position: "absolute"})
    .to("#SimpleText2", {opacity: 0})
    .to("#SimpleText3", {opacity: 1})
    .to("#FCH5", {opacity: 1, pointerEvents: "all"})
    .to("#StorageItems2", {opacity: 1, position: "relative"})
    .to("#Terminal2", {opacity: 0}, "<")
    .to("#Terminal3", {opacity: 1})

}

export const Phase7_Interactive = ({setShowStones}) => {
    gsap.timeline({})
    .to("#FCH5", {opacity: 0, pointerEvents: "none"})
    .to("#FCH5", {position: "absolute"})
    .to("#SimpleText3", {opacity: 0})
    .to("#SimpleText4", {opacity: 1})
    .to("#InvisLi", {opacity: 1, position: "relative"})
    .to("#Terminal3", {opacity: 0, onComplete: () => {setShowStones(true)}}, "<")
    .to("#FCH6", {opacity: 1, pointerEvents: "all"})

    gsap.timeline({repeat: -1, delay: 2})
    .to("#Terminal4", {opacity: 1, duration: 1})
    .to("#Terminal4", {opacity: 0, duration: 1})
    .to("#Terminal5", {opacity: 1, duration: 1})
    .to("#Terminal5", {opacity: 0, duration: 1})
}

export const Phase8_Interactive = ({setStones1, setStones2, stones1, stones2}) => {
    gsap.killTweensOf("#Terminal4");
    gsap.killTweensOf("#Terminal5");

    gsap.timeline({})
    .to("#FCH6", {opacity: 0, pointerEvents: "none"})
    .to("#FCH6", {position: "absolute"})
    .to("#SimpleText4", {opacity: 0})
    .to("#SimpleText5", {opacity: 1})
    .to("#Terminal4", {opacity: 0})
    .to("#Terminal5", {opacity: 0}, "<")
    .to("#FCH7", {opacity: 1, pointerEvents: "all", 
        onComplete: () => {
            setStones1(stones1);
            setStones2(stones2)
        }
    })

    gsap.timeline({repeat: -1, delay: 2})
    .to("#Terminal6", {opacity: 1, duration: 1})
    .to("#Terminal6", {opacity: 0, duration: 1})
    .to("#Terminal7", {opacity: 1, duration: 1})
    .to("#Terminal7", {opacity: 0, duration: 1})
    .to("#Terminal8", {opacity: 1, duration: 1})
    .to("#Terminal8", {opacity: 0, duration: 1})
    .to("#Terminal9", {opacity: 1, duration: 1})
    .to("#Terminal9", {opacity: 0, duration: 1})
}

export const Phase9_Interactive = ({setShowNames, setUpdateIds}) => {

    gsap.killTweensOf("#Terminal6");
    gsap.killTweensOf("#Terminal7");
    gsap.killTweensOf("#Terminal8");
    gsap.killTweensOf("#Terminal9");

    gsap.timeline({})
    .to("#FCH7", {opacity: 0, pointerEvents: "none"})
    .to("#SimpleText5", {opacity: 0})
    .to("#SectionX_LogicContainer", {opacity: 0, pointerEvents: "none"}, "<")
    .to("#SectionX_LogicContainer2", {opacity: 0, pointerEvents: "none"}, "<")
    .to("#SectionX_ContractWrapper", {opacity: 0, pointerEvents: "none"}, "<")
    .to("#SectionX_ImageContainer", {height: "0%", pointerEvents: 'none', borderBottom: 'none'}, "<")
    .to("#SectionX_ImageContainer2", {height: "40%", delay: 1, pointerEvents: 'all', opacity: 1, borderBottom: "1px solid rgb(85, 85, 85)"})
    .to("#I1", {opacity: 1})
    .to("#FCH7", {position: "absolute", 
        onComplete: () => 
        {
            setShowNames(true);
            setUpdateIds(true);
        }
    })
    .to("#Terminal6", {opacity: 0})
    .to("#Terminal7", {opacity: 0}, "<")
    .to("#Terminal8", {opacity: 0}, "<")
    .to("#Terminal9", {opacity: 0}, "<")
    .to("#SectionX_ContractTitle", {opacity: 1})
    .to("#SectionX_ContractTitle2", {opacity: 1}, "<")
    .to("#FCH8", {opacity: 1, pointerEvents: "all"})
}

export const Phase10_Interactive = ({setShowNames, setUpdateIds}) => {

    gsap.killTweensOf("#Terminal6");
    gsap.killTweensOf("#Terminal7");
    gsap.killTweensOf("#Terminal8");
    gsap.killTweensOf("#Terminal9");

    gsap.timeline({})
    .to("#FCH8", {opacity: 0, pointerEvents: "none"})
    .to("#FCH8", {position: "absolute"})
    .to("#Terminal15", {opacity : 0})
    .to("#FCH9", {opacity: 1, pointerEvents: "all"})
}

export const Phase_Interactive_Basic = ({Id1, Id2}) => {

    gsap.timeline({})
    .to(Id1, {opacity: 0, pointerEvents: "none"})
    .to(Id1, {position: "absolute"})
    .to(Id2, {opacity : 1, pointerEvents: "all", position: "relative"})
}


export const StorageSpacesSwap = ({
    idButton1,
    idButton2,
    idPrevContainer,
    idCurrentContainer,
    wait
}) => {
    gsap.timeline({})
    .to(idButton2, {opacity: 0, pointerEvents: 'none'})
    .to(idPrevContainer,
        {opacity: 0, display: 'none'}, "<",
    )
    .to(idCurrentContainer, {
        opacity: 1,
        pointerEvents: 'all',
    })
    .to(idButton1, {
        duration: (wait) ? wait : 1
    })
    .to(idButton2, {opacity: 1, pointerEvents: 'all'})
}

export const RevealHiddenContent = ({before, after}) => {
    gsap.timeline({})
    .to(before, {opacity: 0, pointerEvents: 'none'})
    .to(after, {opacity: 1, pointerEvents: 'all'})
}

export const RevealOnly = ({id, delay}) => {
    gsap.timeline({})
    .to(id, {
        opacity: 1,
        pointerEvents: 'all', 
        delay: (delay) ? delay: 0
    })
}

export const HideOnly = ({id, delay}) => {
    gsap.timeline({})
    .to(id, {opacity: 0, pointerEvents: 'none', delay: (delay) ? delay: 0})
}

export const HideOnlyAbs = ({id, delay}) => {
    gsap.timeline({})
    .to(id, {opacity: 0, pointerEvents: 'none', delay: (delay) ? delay: 0})
    .to(id, {position: "absolute"})
}
export const PhaseSubText = {

    Phase1: `

    `,
    Phase2: `
        HoneyBadger is a storage and permission management system for Solidity smart contracts.  
        It defines a shared storage layer,
        leveraging our novel methodology (formalized in ERC-7844) 
        to enable useful capabilities including dynamic in-place storage extension,
        custom collision-free storage management, and seamless cross-contract interoperability.

        <br/><br/>

        Let's introduce some smart contracts, which we will link to the HoneyBadger 
        layer with access controls.
    `,
    Phase3: `
Managing permissions is critical in shared storage systems to prevent unauthorized access or errors. HoneyBadger simplifies this process with two modes:  
<ul>
    <li><b>Basic Mode:</b> Quickly set up standard access controls.</li>
    <li><b>Advanced Mode:</b> Configure granular permissions for complex use cases.</li>
</ul>

Let’s assign permissions to ensure our smart contracts can interact securely.
    `,
    Phase4: `
With permissions set, the next step is to define a data structure for our system. HoneyBadger lets you create flexible storage spaces that can evolve as your needs change.  
<br/><br/>
Let’s start by creating a storage space to track user information, beginning with fields for a user’s name and age.
    `,
    Phase5: `
A storage space is a logical container for related data. In this phase, we’ll define the <i>user</i> object, with fields for a user’s name and ID.  
<br/><br/>
For example, imagine a Web3 app storing profiles. HoneyBadger ensures this data remains organized, collision-free, and accessible only by authorized contracts.
    `,
    Phase6: `
    The <i>init_create</i> function initializes a storage space with specific data types. 
    For example, we’ll define a <i>user</i> storage space with fields for <i>name</i> (string) 
    and <i>ID</i> (integer).  
    `,
    Phase7: `
    Now that our storage space is ready, we’ll add user data dynamically. For Bob, we’ll 
    create an entry with the <i>push</i> function, storing his unique ID (1) and name 
    ("Bob"). Similarly, we’ll add Sally (ID 2, "Sally").  
    <br/><br/>
    HoneyBadger’s dynamic allocation ensures you only pay for the storage you need, 
    instead of reserving fixed slots upfront. Let’s add Bob and Sally to the database.
    `,
    Phase8: `
    To add entries for Bob and Sally in the storage system, we’ll use the <i>push</i> 
    function. This function reserves storage in the storage space, assigning 
    each entry a unique storage slot based on its index.
    <br/><br/>
    For example, Bob’s data (ID 1) will automatically be stored in the first slot, 
    and Sally’s data (ID 2) in the second slot. HoneyBadger abstracts the complexity 
    of managing storage layout, ensuring all entries remain distinct and easily 
    retrievable. The system also supports address indexing and other advanced features.
    `,
    Phase9: `
    Bob and Sally want to track their stone collections. Using the <i>insert_new_member</i> 
    function, we’ll add a <i>stones</i> field to the existing <i>user</i> object.  
    <br/><br/>
    In traditional systems, adding fields like this -- if it's supported at all, -- 
    often requires redeploying the entire 
    contract with new logic. HoneyBadger lets you modify your schema 
    on-chain without redeployment, saving time and gas while avoiding migrations. Let’s 
    add the <i>stones</i> field now.
    `,
    Phase10: `
    Now that the <i>stones</i> field is added, we’ll record Bob and Sally’s collections. 
    Bob has 40 stones, while Sally has 20.  
    <br/><br/>
    HoneyBadger’s structured storage ensures that each user’s data is securely recorded 
    and linked to their unique ID, preventing overwrites or accidental mix-ups. Let’s 
    store Bob and Sally’s collections in the database.
    `,
    Phase11: `
    Data integrity is critical in shared systems. To ensure Bob’s (ID 1) and Sally’s 
    (ID 2) data remains distinct, we’ll store their IDs alongside their names and 
    collections.  
    <br/><br/>
    HoneyBadger prevents mix-ups by linking all data to unique identifiers 
    -- typically address or sequential uint -- ensuring scalable,
    collision-free storage even when handling thousands of users or entries. Let’s 
    secure their data now.
    `,
    Phase12: [
        `
Now that the system is configured, we can enable tokenized trading between Bob and 
Sally. HoneyBadger allows the Token and Market contracts to interact with shared 
storage, tracking offers, balances, and transaction details in a unified space.  
<br/><br/>
By leveraging HoneyBadger’s shared storage, we eliminate the need for external middleware 
or custom syncing logic. This reduces development complexity and minimizes gas costs 
in multi-contract systems. Let’s begin by connecting the Token and Market contracts.
        `,
        [`
        We can facilitate trading by updating our Token and Market contracts. These contracts 
        will share storage via HoneyBadger, allowing them to read and write data in a unified 
        environment without duplicating state.
        <br/><br/>
        HoneyBadger ensures that permissions are enforced, so only authorized contracts 
        can interact with sensitive trade and balance data. Let’s establish this connection now.
        `
        ,
        `
            First, we’ll create two storage spaces: one to track the token’s supply and metadata, 
            and another to store offer details such as quantities and timestamps.  
            <br/><br/>
            For efficiency, offers are encoded as strings, which link multiple trades to a single 
            user ID. This allows us to scale the system without over-complicating the data structure.
        `
        ,
        `
            Now let’s initialize the Token contract. We’ll allocate a new entry with <i> push </i> in Storage Space 1 
            for the token's metadata, then set its name and total supply.  
            <br/><br/>
            For this demo, we’ll create a token called "Stone Token" with an initial supply of 
            100 units. This supply will be tracked in the storage space and updated dynamically 
            as trades occur.
        `]
    ],
    Phase13: `
    Bob and Sally realize their token lacks intrinsic value, making trades less meaningful. 
    To solve this, they agree to tie their token to USDT, adding real-world value to their 
    transactions.  
    `,
    Phase14: `
    To add utility to their token, Bob and Sally update the Token contract to bundle 
    transactions with banked USDT. This ensures the token has a stable value, making it 
    useful for trading.  
    <br/><br/>
    With HoneyBadger, storage updates are seamless, allowing you to redeploy contracts 
    and reconfigure storage without disrupting data integrity. Let’s update the Token 
    contract now.
    `,
    Phase15: `

        With the new Token contract deployed, we need to update its permissions. We’ll 
        revoke the old contract’s access and grant the new one permission to interact with 
        the shared storage.  
        <br/><br/>
        HoneyBadger’s permission system ensures a smooth transition by allowing you to 
        precisely manage access during contract upgrades. Let’s adjust the permissions.
    `,
    Phase16: `

    Bob and Sally each send 50 USDT to the protocol as collateral, ensuring their trades 
    are secure. They also provide 50 stones to a middleman, who verifies transactions 
    and facilitates the ultimate exchange.  
    <br/><br/>
    HoneyBadger ensures transparency and integrity by tracking collateral, trade details, 
    and balances in shared storage, minimizing the risk of disputes while 
    facilitating efficient cross-contract interoperability. Let’s record these 
    transactions now.
    `,
    Phase17: `

    Bob wants to create an offer: 10 stones for 20 tokens. Using the Market contract, 
    we encode the offer details and store them in the shared MarketOffers storage space, 
    linked to Bob’s ID.  
    <br/><br/>
    HoneyBadger’s collision-free storage ensures that even with multiple offers from 
    different users, each record remains distinct and easy to retrieve. Let’s create 
    and store Bob’s offer now.
    `,
    Phase18: `
    Later in the day, Sally sees Bob's offer and decides to accept.  She 
    approves the Market contract to transfer 20 tokens from her account,
    then accepts the offer.   
`,
    Phase19: `
        Success!  Together, we've leveraged HoneyBadger to help Bob and Sally, allowing them to 
        overcome their time zone differences and trade stones efficiently.  
    `
}

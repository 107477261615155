import styles from '../../CSS/SectionX.module.css';
import Build from '../../../../../assets/Build.svg';
import Traction from '../../../../../assets/Traction.svg';

export default function SubSection1()
{
    return(
        <>
            <h2
            id = "SectionX_Header" 
            className={styles.HeadingMain}
        >
            Driving Success at Every Stage of the Web3 Development Lifecycle
        </h2>

        <div 
            id = "SectionX_TwoItemsContainer"
            className={styles.TwoItemsContainer}
        >

            <div className = {styles.InnerItem}>

                <img src = {Build} className = {styles.Img} />
                <h2 className = {styles.Header2}>
                    Framework
                </h2>
                <p className = {styles.Pg}>
                    Build and Evolve
                </p>

                <p className = {`${styles.Pg2} HN`}>
                    Highly-optimized storage and permission management 
                    system with near-universal compatability.

                </p>
            </div>

            <div className = {styles.InnerItem}>
                <img src = {Traction} className = {styles.Img} />
                <h2 className = {styles.Header2}>Token Ecosystem</h2>
                <p className = {styles.Pg}>Drive Engagement</p>
                <p className = {`${styles.Pg2} HN`}>
                    Leverage token integration and $HB grants to 
                    drive engagement with your project.
                </p>
            </div>
        </div>
    </>

    )
}
import gsap from "gsap";

export default function Create_Section1_Scroll_Animations()
{
    gsap.fromTo(
        "#Section1_Container",
        { background: "linear-gradient(180deg, #0e0e0f, #202036, #2b3a67, #40416c, #563d7c, #6d4a92)" }, // Start with black to midnight blue
        {
            background: "linear-gradient(180deg, #0e0e0f, #202036, #2b3a67, #40416c, #563d7c, #6d4a92)", // More complex colors to reduce banding
            scrollTrigger: {
                trigger: "#Section1_Container",
                start: 'top top',
                end: "bottom 50%", 
                scrub: true, // Makes the gradient animate smoothly
            }
        }
    );
    
    // // Optional: Animate the gradient colors further as you scroll for dynamic effects
    // gsap.to("#Section1_Container", {
    //     scrollTrigger: {
    //         trigger: "#Section1_Container",
    //         start: 'top top',
    //         end: 'bottom bottom',
    //         scrub: true,
    //     },
    //     // Gradually animate between gradient colors
    //     backgroundPosition: "200% 0%",
    //     ease: "power1.inOut",
    //     duration: 5, // Smooth transition duration for the animated gradient
    //     repeat: -1, // Infinite loop to continuously shift the gradient
    // });
    gsap.fromTo("#Section1_TextContainer", 
        { opacity: 1 },
        { opacity: 1,
          scrollTrigger: {
            trigger: "#Section1_TextContainer",
            start: "top 10%",
            end: "+=225%",
            pin: true,
            pinSpacing: true,
            invalidateOnRefresh: true,
          }
        }
    );

    gsap.fromTo("#Section1_Header", 
        { opacity: 0, x: -100 },
        { opacity: 1, x: 0,
          scrollTrigger: {
            trigger: "#Section1_Header",
            start: "top 80%",
            end: "top 50%",
            scrub: true,
            invalidateOnRefresh: true,
          }
        }
    );

    gsap.fromTo("#Section1_Paragraph", 
        { opacity: 0, x: -100 },
        { opacity: 1, x: 0,
          scrollTrigger: {
            trigger: "#Section1_Paragraph",
            start: "top 80%",
            end: "top 50%",
            scrub: true,
            invalidateOnRefresh: true,
          }
        }
    );

    gsap.to("#MainAxiomContainer", 
        {
            opacity: 1,
            scale: 1,
            scrollTrigger:{
                trigger: "#MainAxiomContainer",
                start: "top 0%",
                end: "+=50%",
                scrub: true,
            }
        }
    )
    gsap.to("#MainAxiomContainer", 
        {
            scrollTrigger:{
                trigger: "#MainAxiomContainer",
                start: "top 65%",
                end: "+=225%",
                pin: true,
                pinSpacing: true,
                invalidateOnRefresh: true,
            }
        }
    )  

    gsap.fromTo("#Axiom1", {x: 310}, {x: 0, scrollTrigger: {
        trigger: "#Axiom1",
        start: "top -50%",
        end: "+=50%",
        scrub: true,
    }})

    gsap.fromTo("#Axiom3", {x: -310}, {x: 0, scrollTrigger: {
        trigger: "#Axiom3",
        start: "top -50%",
        end: "+=50%",
        scrub: true,
    }})

}

export const ExpandContainer = (
    ContainerId, 
    IconContainerId, 
    ParagraphId, 
    LongDescriptionId, 
    index,
    timelineRefs
) =>
{
    const {expandTL, expandTL2, expandTL3, closeTL, closeTL2, closeTL3} = timelineRefs;
    if(index === 1 && closeTL.current) closeTL.current.kill();
    else if(index === 2 && closeTL2.current) closeTL2.current.kill();
    else if(index === 3 && closeTL3.current) closeTL3.current.kill();

    const tl = gsap.timeline();
    tl.to(ContainerId, {height: "150px", marginTop: "12.5px"});
    tl.to(IconContainerId, {opacity: 0, position: 'absolute', duration: 0}, "<");
    tl.to(ParagraphId, {opacity: 0, position: 'absolute', duration: 0}, "<");
    tl.to(LongDescriptionId, {position: "relative", opacity: 1});

    (index === 1) ? expandTL.current = tl : (index === 2) ? expandTL2.current = tl : expandTL3.current = tl;
}

export const CloseContainer = (
    ContainerId, 
    IconContainerId, 
    ParagraphId, 
    LongDescriptionId, 
    index,
    timelineRefs) =>
{
    const {expandTL, expandTL2, expandTL3, closeTL, closeTL2, closeTL3} = timelineRefs;

    if(index === 1 && expandTL.current) expandTL.current.kill();
    else if(index === 2 && expandTL2.current) expandTL2.current.kill();
    else if(index === 3 && expandTL3.current) expandTL3.current.kill();

    const tl = gsap.timeline();
    tl.to(LongDescriptionId, {position: "absolute", opacity: 0, duration: 0});
    tl.to(ContainerId, {height: "125px", marginTop: "0px"});
    tl.to(IconContainerId, {opacity: 1, position: 'relative'});
    tl.to(ParagraphId, {opacity: 1, position: 'relative'}, "<");

    (index === 1) ? closeTL.current = tl : (index === 2) ? closeTL2.current = tl : closeTL3.current = tl;
}

import styles from '../../../../CSS/SectionX.module.css';
import int from '../../../../CSS/SectionX_Interactive.module.css';
import Demo from '../Demo/Demo.module.css';
import '../../../../../../../css/keyFonts.css'

export default function DemoContentSection({
    containerId,
    headingText,
    subText,
    onClick,
    buttonText,
    hidden,
    dropDown,
    alignLeft,
})
{
    return(
        <div id = {containerId} className = 
        {`${int.IntroWrapper} ${(dropDown === true ? int.Drop : '')} ${hidden ? int.Hidden : ""}`}>
            <h2 className = {styles.Header4}>
                {headingText}
            </h2>
            <p 
            className={`${Demo.SubHeading} ${alignLeft ? Demo.Left : ''} HN`} 
            style={{ marginTop: "20px", width: "80%" }}
            dangerouslySetInnerHTML={{ __html: subText }}
            />
            <button 
                onClick = {() => {onClick()}}
                className = "glowingButton" 
                style = {{marginTop: "20px", width: "30%"}}
            >
                {buttonText}
            </button>
        </div>
    )
}
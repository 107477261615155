import '../../css/App.css';
import '../../css/marquee.css';
import '../../css/TextStyles.css';
import '../../css/TitleTextStyles.css';

import { gsap } from 'gsap/gsap-core'
import { SplitText } from 'gsap/SplitText';
import { MotionPathPlugin } from 'gsap/all';
import { useEffect, useRef, useState } from 'react'

export default function TitleText(props)
{
  gsap.registerPlugin(SplitText)
  gsap.registerPlugin(MotionPathPlugin)

  const [titleSplitText, setTitleSplitText] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const TL = useRef(gsap.timeline({}));

  useEffect(() => {
    if(!titleSplitText)
    {
      setTitleSplitText(new SplitText(".fr", { type: "chars" }));
    }
  }, [])

  useEffect(() => 
  {
    if(!titleSplitText) return;

    TL.current.fromTo(".fr", { x: 20 },{x: 0, opacity: 1, duration: 0.75,stagger: 0.1,delay: 0.75})
      .fromTo(".ab", {x: 20, opacity: 0}, {opacity: 1,duration: .75, x:0})
      .to(".TTRow", {opacity: 1, duration: .5, stagger: .25});

    setLoaded(true);

  }, [titleSplitText]);


    useEffect(() =>
    {
      if(loaded !== true) return;
      if(TL.current.isActive()) return;

      if(props.hovered == true) HoverAnimations();
      else HoverOutAnimations();

    }, [props.hovered, loaded])


  return(
    <>
      <div className = "TextContainer">
        <div className='TextCol'>
          <h2 className = {"PS fr HomeTitle"}>HONEYBADGER</h2>
          <p className = {"montserrat ab Tagline"}>
            Make Your Project Tougher Than Solidity's Biggest Predators 
          </p>
          <p className = {"titleText ab2 DenText"}>
          🖱️ SCROLL TO ENTER THE DEN
          </p>
        </div>
      </div> 
      {Options}

    </>
  )
}

const Options = 
(
  <div className = "BenefitsContainer Montserrat">
    <div className = 'TTRow'>
      <h2>Plug-and-Play</h2>
    </div>

    <div className = 'TTRow'>
      <h2>In-Place Storage Adaptability</h2>
    </div>

    <div className = 'TTRow'>
      <h2>Seamless Integrations</h2>
    </div>

    <div className = 'TTRow'>
      <h2>Streamlined Development</h2>
    </div>

    <div className = 'TTRow'>
      <h2>Reduced Costs</h2>
    </div>
  </div>
)

const HoverAnimations = () => 
{
  gsap.killTweensOf(".ab");
  gsap.killTweensOf(".ab2");

  gsap.to(".ab", {opacity: 0, position: "absolute", duration: 0})
  gsap.to(".ab2", {opacity: 1, duration: .5})
}

const HoverOutAnimations = () => 
{
  gsap.killTweensOf(".ab");
  gsap.killTweensOf(".ab2");

  gsap.to(".ab2", {opacity: 0})
  gsap.to(".ab", {opacity: 1, position: "relative"})
}

import '../../../../css/keyFonts.css';
import pageStyles from '../Common/page.module.css';
import styles from '../Common/page.module.css';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White, P2} from '../Common/CommonElements'
import CoreAdvantages from './CoreAdvantages';
import storageSpaceCarbon from '../../../../assets/storageSpaceCarbon.png';
import insertNewCarbon from '../../../../assets/insertNewMemberCarbon.png';
import singleHopExampleCarbon from '../../../../assets/singleHopExampleCarbon.png';

export default function Page_1({mobileMode})
{
    return (
        <PageLayout mobileMode = {mobileMode}>
            <h2 className={styles.PageHeader}>
                Pioneering New Limits for Smart Contract Scalability, Cost-Effectiveness, and Interoperability
            </h2>

            <P>
                <Tab />
                HoneyBadger is a Solidity storage and permission management framework 
                that expands on existing upgradeability 
                patterns by introducing dynamically extendable, permissioned storage. While 
                traditional models require redeployments, manual slot management, and high 
                audit costs to modify storage, HoneyBadger enables in-place storage evolution. 
                Developers can modify and expand storage without breaking existing dependencies, 
                ensuring that upgrades remain seamless, efficient, and secure. With deterministic 
                schema evolution, built-in role-based access control, and gas-optimized storage interactions, HoneyBadger reduces upgrade friction and mitigates the risks of storage misalignment or corruption.
                <br/><br/>
                <Tab />
                By standardizing cross-contract storage interoperability, HoneyBadger 
                enables multiple smart contracts to operate on a single, unified storage layer,
                eliminating redundant mappings and inefficient data duplication. 
                This architecture ensures that protocols remain modular and composable, 
                making it ideal for DeFi platforms, DAOs, and evolving blockchain ecosystems.
                Additionally, HoneyBadger optimizes execution efficiency, enforcing a single-hop 
                storage access model that significantly lowers gas costs compared to more complex 
                multi-contract interactions. By simplifying upgrades and improving contract 
                maintainability, HoneyBadger allows developers to build scalable, future-proof 
                applications with a streamlined approach to storage management.
            </P>

            <div className={pageStyles.IFrameContainer}>
                <iframe
                    style={{
                        width: "100%",
                        maxWidth: "560px",
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                    }}
                    src="https://www.youtube.com/embed/LngHy8ixdvs?start=32"
                ></iframe>
                <P><i>Understand HoneyBadger in Minutes!</i></P>
            </div>

            <CoreAdvantages />

            <H>How it Works</H>
            <Ul>
                <Li>
                    <H2>Storage Spaces and Extendible Structs</H2>
                    <P>
                    HoneyBadger organizes on-chain data into storage spaces, each of which 
                    holds one or more “extendible structs.” Instead of embedding mappings 
                    in every contract or predefining a rigid slot layout, you can dynamically 
                    add new fields to these structs as your protocol evolves. The critical 
                    advantage here is that existing fields remain intact—you aren’t rewriting 
                    earlier slots or migrating data when introducing new members.

                    <br/><br/>
                    <b>Defining a Storage Space:</b>
                    <br/>
                    Each space is initialized by calling a function like:
                    </P>

                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                    <div className={pageStyles.Center}>
                        <img 
                            src={storageSpaceCarbon} 
                            className={pageStyles.Img}
                        />
                    </div>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />

                    <P>
                    This creates a spaceId representing a discrete namespace for your structs. 
                    You can then store multiple entries (like user records or asset data) in that space.
                    </P>

                    
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                    <div className={pageStyles.Center}>
                        <img 
                            src={insertNewCarbon} 
                            className={pageStyles.Img}
                        />
                    </div>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />

                    <P>
                        HoneyBadger appends the new field at a fresh offset. That way, 
                        any contract referencing the older fields continues operating 
                        normally, and only newly deployed logic or modules need to handle 
                        the added field.
                        <br/><br/>
                        <b>Why it Works: </b>
                    </P>
                    <div style = {{marginLeft: "20px"}}>
                        <P >
                            <i>Collision Avoidance - </i>
                            HoneyBadger calculates a unique offset for each new field, 
                            ensuring no overlap with prior struct members.
                            <br/>

                            <i>Preserved Layout for Older Code - </i>
                            Legacy contracts still see the original fields in the same positions. They 
                            remain unaware of the appended members, which appear only to updated logic 
                            that references them by index.
                            <br/>
                        </P>
                    </div>
                    
                    <P>
                        <b>Practical Considerations</b>
                        <br/>
                    </P>

                    <div style = {{marginLeft: "20px"}}>
                        <P>


                            <i>Member Indexing - </i> 
                            Each field is identified by a memberIndex. You must track 
                            these carefully when reading or writing a field.
                            <br/>

                            <i>Data Retrieval - </i> 
                            Contracts specify (memberIndex, entryIndex, storageSpace) when calling 
                            get(...) or put(...). If a new field is appended, only 
                            logic aware of that new index will use it.
                            <br/>

                            <i>Upgrading Safely - </i> Because you’re not re-mapping old slots, 
                            you avoid the risk of overwriting active data or messing with existing ABIs.
                        </P>
                    </div>

                    <P>
                        By decoupling where the data lives (the storage space) from how many fields exist 
                        (the extendible struct), HoneyBadger enables in-place expansions while leaving 
                        older modules untouched. This design underpins the rest of HoneyBadger’s 
                        “how it works” features, such as hash-based indexing, single-hop reads/writes, 
                        and secure permission checks.
                    </P>

                </Li>

                <S />

                <Li>
                    <H2>Deterministic Hash-Based Indexing</H2>
                    <P>
                        HoneyBadger prevents slot conflicts by assigning each field a unique hash-derived offset. 
                        Whenever a contract writes or reads a particular field in a storage space, HoneyBadger 
                        calculates a slot key with a function similar to:
                    </P>

                    <P>
                        For example, 
                        <br/>
                        <code>
                            slotKey = keccak256(abi.encodePacked(spaceId, entryIndex, memberIndex));
                        </code>
                        <br/><br/>
                        With this approach, 
                        each <code>(spaceId, entryIndex, memberIndex)</code> combination maps to a distinct 256-bit slot 
                        in the EVM, greatly reducing the risk of accidental overwrites. 
                        Since newly added fields receive fresh member indices, they can coexist with older 
                        data—no manual slot reservations are necessary.
                    </P>

                    <P>
                        <b>How It Avoids Collisions</b>
                    </P>
                    <div style={{ marginLeft: "20px" }}>
                        <P>
                            <i>Unique Combinations – </i> 
                            Each field is a unique tuple of <code>(spaceId, entryIndex, memberIndex)</code>. 
                            Because keccak256 hashing effectively randomizes these tuples, the chance of 
                            collision is astronomically low.
                            <br/><br/>
                            <i>Debug-Friendly – </i>
                            Developers can replicate the same hashing offline to locate or verify any 
                            stored field by reusing the same <code>keccak256</code> formula.
                            <br/><br/>
                            <i>Consistent Expansions – </i>
                            Whether you add a single field or multiple new ones in a single upgrade, 
                            each addition calculates its own hash. Older fields remain in their existing slots.
                        </P>
                    </div>

                    <P>
                        This deterministic hashing framework underpins HoneyBadger’s collision-free expansions. 
                        It complements the extendible structs concept by ensuring new fields occupy their own 
                        unique storage locations. By combining both hashed offsets and dynamic struct membership, 
                        HoneyBadger eliminates the painstaking manual slot tracking found in many upgradeable patterns.
                    </P>
                </Li>

                <S />
                <Li>

                    <H2>Single-Hop Reads/Writes</H2>
                    <P>
                        <Tab />
                        In many upgradeable systems—like Proxy-Delegate or multi-facet Diamond setups—data retrieval 
                        and updates can involve multiple delegatecalls or bridging contracts. HoneyBadger removes these 
                        layers by letting each contract call a direct <code>put</code> or <code>get</code> function 
                        on the storage contract. This streamlined approach lowers gas costs, reduces call complexity, 
                        and helps maintain clarity in large ecosystems.
                    </P>

                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />
                    <div className={pageStyles.Center}>
                        <img 
                            src={singleHopExampleCarbon} 
                            className={pageStyles.Img}
                        />
                    </div>
                    <div style={{ height: mobileMode === true ? "40px" : '40px' }} />

                    <P>
                        <b>Key Benefits of Single-Hop Access:</b>
                    </P>
                    <div style={{ marginLeft: "20px" }}>
                        <P>
                            <i>Reduced Gas Overhead –</i> 
                            Fewer call layers mean fewer <code>delegatecall</code> or aggregator patterns, 
                            especially valuable in high-frequency contexts like DeFi.
                            <br/><br/>
                            <i>Simplified Architecture –</i>
                            Contracts don’t depend on intermediary proxies to store or fetch data, which 
                            lowers code complexity and potential attack vectors.
                            <br/><br/>
                            <i>Easy Batch Updates –</i>
                            With HoneyBadger’s multi-field operations (e.g., <code>put_batch</code>), 
                            you can perform multiple writes in one transaction, cutting extra calls even further.
                        </P>
                    </div>

                </Li>

                <S />

                <Li>
                    <H2>Access Controls</H2>
                    <P>
                        <Tab />
                        In HoneyBadger, storage operations are only accessible to 
                        approved entities. Each storage 
                        space (or individual field, if you prefer) can be locked behind a role-based system or 
                        a permission check. Developers decide which modules or addresses can invoke critical 
                        functions like <code>insert_new_member</code> or <code>put</code>, preventing unauthorized 
                        changes or data leaks. These checks typically run at the start of each write function, 
                        reverting if the caller lacks the required privileges.
                    </P>

                    <P>
                        <b>How Access Control Works:</b>
                    </P>
                    <div style={{ marginLeft: "20px" }}>
                        <P>
                            <i>Role-Based Permissions –</i> 
                            Each address can hold a specific role (e.g., ADMIN, WRITER, READER). 
                            HoneyBadger’s methods verify the caller’s role before allowing writes or schema expansions.
                            <br/><br/>
                            <i>Fine-Grained Restrictions –</i>
                            Some systems limit writes to certain members or storage spaces. If a contract 
                            only needs read access, you grant a lower role that can’t modify fields.
                            <br/><br/>
                            <i>Governance Integration –</i>
                            You can integrate decentralized governance to manage roles or add new 
                            authorized modules. This ensures the system can evolve without losing 
                            security constraints.
                        </P>
                    </div>

                    <P>
                        By placing a lightweight permission check at the start of each relevant function (such as 
                        <code>put(...)</code> or <code>insert_new_member(...)</code>), HoneyBadger ensures that only 
                        authorized entities modify storage. This is especially important in multi-contract 
                        ecosystems where different modules share the same data but require distinct privileges.
                    </P>
                </Li>

                <Li>
                    <H2>Initialization in a Few Simple Steps</H2>
                    <P>

                        <b>1. Set Initial Permissions</b>
                        <br/>
                        Call <code>update_permissions</code> to grant or revoke access for specific 
                        addresses or contracts. For instance:
                        <br/>
                        <code>update_permissions(admin, FULL_ACCESS, false)</code>
                        <br/>
                        <code>update_permissions(logicContract, WRITE_ACCESS, false)</code>
                        <br/>
                        This ensures only authorized entities can create or modify storage spaces.

                        <br/><br/>
                        <b>2. Create a Storage Space</b>
                        <br/>
                        Call <code>init_create</code>, passing arrays of types and sizes to define 
                        your initial fields. For example:
                        <br/>
                        <code>init_create([1, 1], [256, 256])</code>
                        <br/>
                        This returns a unique <code>spaceId</code> that represents your newly 
                        allocated struct. 

                        <br/><br/>
                        <b>3. Optionally Push or Insert New Fields</b>
                        <br/>
                        If you need more fields right away—or in the future—you can:
                        <br/>
                        <code>push(spaceId)</code> to add another member slot; or
                        <br/>
                        <code>insert_new_member(spaceId)</code> if you want to explicitly define 
                        a new field with a known type or size.
                        <br/>
                        Each new field slot is placed at a collision-free offset without disturbing 
                        existing data.

                        <br/><br/>
                        <b>4. Store and Retrieve Data</b>
                        <br/>
                        Once the space is created, authorized contracts can call <code>put</code> 
                        (or <code>put_batch</code>) to write values, and <code>get</code> to read them. 
                        Because HoneyBadger abstracts away manual slot management, you reference fields by 
                        <code>(spaceId, entryIndex, memberIndex)</code> instead of tracking specific storage 
                        slot numbers.

                        <br/><br/>
                        <b>5. Refine Permissions as Needed</b>
                        <br/>
                        If a new contract or role requires expanded privileges (e.g., to append fields), 
                        call <code>update_permissions</code> again. This maintains a secure environment 
                        in which only trusted parties can alter core data structures.
                    </P>

                    <P>
                        <Tab />
                        With these steps, you establish a robust foundation for dynamic, upgrade-friendly 
                        storage. As your system grows or logic evolves, you can safely append new fields 
                        or create additional spaces without redeploying or migrating existing data.
                    </P>
                </Li>

            </Ul>

            <S />
            <H>Supporting Features (Non-Comprehensive)</H>
            <Ul>

                <Li><b>Address Indexing</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Allows contracts to map storage entries directly to addresses, simplifying lookups and reducing manual index management.</P>
                </div>

                <Li><b>Governance Modules</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Integrate easily with on-chain or off-chain governance, enabling permission updates and schema expansions through decentralized decision-making.</P>
                </div>

                <Li><b>Dynamic Modules System</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Supports deploying new feature modules at any time without disrupting existing contracts or storage layouts.</P>
                </div>

                <Li><b>Granular Permission Management</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Enables fine-grained role assignments so each contract, address, or module can only access data it’s authorized to modify or view.</P>
                </div>

                <Li><b>Batch Operations</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Provides functions to read or write multiple fields at once, minimizing repetitive calls and cutting gas costs for large updates.</P>
                </div>

                <Li><b>Optimized 256-Bit Operations</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Leverages low-level Solidity assembly and bitwise packing for efficient reads and writes, reducing overhead in high-throughput environments.</P>
                </div>

                <Li><b>Full Type Support</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Accommodates common Solidity types (uint, int, bool, address, and bytes32) alongside more complex ones like dynamic strings or arrays.</P>
                </div>

                <Li><b>Arrays (Uses String Type)</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Implements arrays via the underlying string type, allowing dynamic-length data structures to be stored and managed without fixed slot reservations.</P>
                </div>
            </Ul>

            <S />
            <H>Future Advancements</H>

            <H2>HoneyBadger Libraries: Optimized Token Standards</H2>
            <Ul>
                <Li><b>HoneyBadger-Integrated OpenZeppelin Libraries</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>
                    Built on the Solady forks for optimal efficiency, 
                    pre-built libraries are a high-priority step.
                    </P>
                </div>
            </Ul>

            <H2>Specialized HoneyBadger Models</H2>
            <Ul>
                <Li><b>HoneyBadger Superfast</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>A high-performance model that includes access controls, 
                    but no input validation.  Recommended for production systems 
                    due to Solidity's implicit typing -- HoneyBadger's second layer of 
                    defenses are often unnecessary, but are useful for testing. </P>
                </div>

                <Li><b>HoneyBadger Modules</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>
                        Leverages a built-in modules system that enables new features 
                        to be added in-place.  This allows development teams to skip 
                        proxy-delegate on their HoneyBadger layer without missing out on 
                        HoneyBadger-layer upgradeability.
                    </P>
                </div>

                <Li><b>HoneyBadger Full-Security Model</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>
                        Strict governance-controlled execution.</P>
                </div>
            </Ul>

            <H2>Cross-Chain Storage & Verification</H2>
            <Ul>
                <Li><b>Cross-Chain Storage Operations Validated With ZK Proofs</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>
                    Contracts on one chain can efficiently fetch and modify another chain’s 
                    HoneyBadger storage, with operations validatd via ZK proofs.
                    </P>
                </div>
            </Ul>

            <H2>Complex Data Type Support</H2>
            <Ul>
                <Li><b>Native Struct Nesting</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Directly store nested structs inside HoneyBadger storage.</P>
                </div>

                <Li><b>Efficient Custom Data Serializations</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Supports optimized variable-length encoding formats, allowing developers to store structured data more efficiently.</P>
                </div>

                <Li><b>Optimized Historical State Storage</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Native versioning support for tracking past states without requiring dedicated snapshot contracts.</P>
                </div>
            </Ul>

            <H2>Optimized Pre-Built Solutions for DeFi, DAOs, and Staking</H2>
            <Ul>
                <Li><b>DAO Governance Module</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Fully-integrated on-chain governance system that allows token-based voting mechanisms to modify storage permissions dynamically.</P>
                </div>

                <Li><b>DeFi Optimized Configurations</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Pre-built settings for lending markets, AMMs, and yield aggregators, ensuring gas-efficient interactions.</P>
                </div>

                <Li><b>Staking & Liquidity Pool Extensions</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Directly integrates staking mechanisms into HoneyBadger storage, eliminating redundant mappings and manual reward distributions.</P>
                </div>
            </Ul>

            <H2>Instant Implementation: Compiler & Code Migration</H2>
            <Ul>
                <Li><b>Automated Solidity-to-HoneyBadger Conversion</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Instantly translates standard Solidity storage layouts into HoneyBadger syntax, removing adoption friction.</P>
                </div>

                <Li><b>Bit-Packed Struct Optimization</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Detects inefficient storage layouts and applies Solidity-native packing techniques for gas savings.</P>
                </div>

                <Li><b>Selective Migration Modes</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Allows developers to transition specific contract components while keeping core logic intact.</P>
                </div>

                <Li><b>Preset Performance Profiles</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Configurable deployment settings optimized for speed, security, or minimal storage overhead.</P>
                </div>

                <Li><b>Pre-Deployment Verification & Simulation</b></Li>
                <div style={{marginLeft: "20px"}}>
                    <P>Runs pre-deployment checks to detect slot conflicts, misaligned offsets, and compatibility issues before launch.</P>
                </div>
            </Ul>

        </PageLayout>
    )
}
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import Get1Cost from '../../../../assets/Get1Cost.png';
import CodeGetBatch from '../../../../assets/CodeGetBatch.png';
import Get1Ex from '../../../../assets/Get1Ex.png';
import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import StringComparison from '../../../../assets/StringComparison.png';

export default function Page_5_2({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>

            <h2 className={styles.PageHeader}>
                Performance Benchmarks: Retrieving Data
            </h2>

            <P>
                This section provides a comprehensive overview of HoneyBadger's 
                data retrieval costs in comparison to basic high-level Solidity 
                models leveraging the Proxy-Delegate pattern.

            </P>

            <H>Notes</H>
            <P>
                Assume slots have been initialized.  Gas costs 
                are calculated with an ETH price of $3,000 and a gas price of 30 gwei.
            </P>
            <S />
            <img 
                style = {{border: "1px solid white"}}
                src = {Get1Cost} 
                className = {styles.Image}
                alt = "Gas for a single get operation - honeybadger (ERC-7844) vs proxy-delegate" />

            <p style = {{color: 'white'}}><i>Gas comparison: Proxy-Delegate vs HoneyBadger</i></p>
            <S />

            <H>Retrieving a Single Value: Uint32</H>
            <P2>
                <i>Notes:</i> Using HoneyBadger's <i>get </i> function.
                
                <br/><br/>

                <b>Basic Implementation + Proxy-Delegate: </b> 4,895 gas
                <br/>
                <i>Cost Estimate:</i> $0.44 USD
                <br/><br/>

                <b>HoneyBadger Superfast:</b> 7,525 gas
                <br />

                <i>Cost Estimate:</i> $0.68 USD

                <br/><br/>

                Retrieval with HoneyBadger is slightly more expensive than 
                using high-level Solidity.  This is largely because HoneyBadger has to perform two  
                <i> sload</i> operations to retrieve the value, fetching the storage space 
                metadata before deriving the target slot.  
            </P2>
            <S />
            <img 
                style = {{border: "1px solid white"}}
                src = {Get1Ex} 
                className = {styles.Image}
                alt = "HoneyBadger get function vs demo (proxy-delegate) code" />
            <p style = {{color: 'white'}}><i>Code comparison: Basic Demo vs HoneyBadger</i></p>

            <S />
            <H>Retrieving 2 Values: Uint32</H>
            <P>
                <i>Notes:</i> Using HoneyBadger's <i>get_batch </i> function.
                
                <br/><br/>

                <b>Basic Implementation + Proxy-Delegate: </b> 7,336 gas
                <br/>
                <i>Cost Estimate:</i> $0.66 USD
                <br/><br/>

                <b>HoneyBadger Superfast:</b> 14,708 gas
                <br />

                <i>Cost Estimate:</i> $1.32 USD

                <br/><br/>
            </P>

            <img 
                style = {{border: "1px solid white"}}
                src = {CodeGetBatch} 
                className = {styles.Image}
                alt = "HoneyBadger get batch function vs demo (proxy-delegate) code" />
            <p style = {{color: 'white'}}><i>Gas comparison: Basic Demo vs HoneyBadger
            get_batch()</i></p>


            <S />
            <H>Retrieving a String</H>
            <P>
                <i>Notes: </i> Using HoneyBadger's <i>get_string</i> function.

                <br/><br/>

                <b>Basic Implementation + Proxy-Delegate: </b> 7,336 gas
                <br/>
                <i>Cost Estimate:</i> $0.52 USD
                <br/><br/>

                <b>HoneyBadger Superfast:</b> 14,708 gas
                <br />

                <i>Cost Estimate:</i> $1.34 USD

                <br/><br/>
            </P>

            <img 
                style = {{border: "1px solid white"}}
                src = {StringComparison} 
                className = {styles.Image}
                alt = "HoneyBadger get batch function vs demo (proxy-delegate) code" />
            <p style = {{color: 'white'}}><i>Gas comparison: Basic Demo vs HoneyBadger
            get_batch()</i></p>


        </PageLayout>
        
    )
}
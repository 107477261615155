import { useEffect, useRef, useState } from 'react';
import styles from '../CSS/SectionX.module.css';
import '../../../../css/keyFonts.css';
import '../../../../css/glowButton.css'

import SectionXBg from './SectionX/SectionXBg';
import SubSection1 from './SectionX/SubSection1';
import SubSection2 from './SectionX/SubSection2';
import SubSection3 from './SectionX/SubSection3/SubSection3';

export default function SectionX_HTML()
{
    const [sceneInView, setSceneInView] = useState(false);
    const sceneRef = useRef();

  useEffect(() =>
  {
    if(!sceneRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        let intersecting = entry.isIntersecting;
        if(sceneInView !== intersecting)
        {
          setSceneInView(intersecting);
        }
      }, {threshold: .1}
    );

    observer.observe(sceneRef.current);

    return () => {
      if (sceneRef.current) {
          observer.unobserve(sceneRef.current);
      }
    }

  }, [sceneRef.current, sceneInView]);

    return(
        <div ref = {sceneRef} className = {styles.Container} id = "SectionX_Container">

            <SectionXBg sceneInView={sceneInView}/>
                     

            <div 
                id = "SectionX_ContentContainer" 
                className = {styles.ContentContainer}
            >
                <SubSection1 />   
                <SubSection2 />
                <SubSection3 />

            </div>
        </div>
    )
}
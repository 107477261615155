import SidebarItem from "../SidebarComponents/SidebarItem";

export function NonTechnical({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'NT1' 
            label = "HoneyBadger Framework" 
            page = {page} 
            pageNumber={1.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT2' 
            label = "Token Ecosystem" 
            page = {page} 
            pageNumber={1.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT3' 
            label = "Genesis Token" 
            page = {page} 
            pageNumber={1.3} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT4' 
            label = "Roadmap" 
            page = {page} 
            pageNumber={1.4} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />
    ]
}

export function PerformanceBenchmarks({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return[
        <SidebarItem 
        key = 'PB1' 
        label = "Modifying Storage" 
        page = {page} 
        pageNumber={5.1} 
        setPage = {setPage}
        mobileSidebarOpen = {mobileSidebarOpen}
        setMobileSidebarOpen = {setMobileSidebarOpen}
    />,
        <SidebarItem 
        key = 'PB2' 
        label = "Retrieving Data" 
        page = {page} 
        pageNumber={5.2} 
        setPage = {setPage}
        mobileSidebarOpen = {mobileSidebarOpen}
        setMobileSidebarOpen = {setMobileSidebarOpen}
    />,
//     <SidebarItem 
//     key = 'PB3' 
//     label = "Deployment Cost" 
//     page = {page} 
//     pageNumber={5.3} 
//     setPage = {setPage}
//     mobileSidebarOpen = {mobileSidebarOpen}
//     setMobileSidebarOpen = {setMobileSidebarOpen}
// />,
    ]
}

export function VideoGuides({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'VG1' 
            label = "Blockchain, Smart Contracts, Scalability" 
            page = {page} 
            pageNumber={6.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'VG2' 
            label = "HoneyBadger Framework made simple." 
            page = {page} 
            pageNumber={6.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
    ]
}

export function KeyAdvantages({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'KA1' 
            label = "In-Place Storage Extendibility" 
            page = {page} 
            pageNumber={4.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA2' 
            label = "Enhanced Cross-Contract Interoperability" 
            page = {page} 
            pageNumber={4.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA3' 
            label = "Streamlined Development and Upgrades" 
            page = {page} 
            pageNumber={4.3} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
            />,
            <SidebarItem 
            key = 'KA4' 
            label = "Powerful Access Controls" 
            page = {page} 
            pageNumber={4.4} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA5' 
            label = "Upgradeability With Decentralization" 
            page = {page} 
            pageNumber={4.5} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA6' 
            label = "Vulnerability Resilience" 
            page = {page} 
            pageNumber={4.6} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA7' 
            label = "Reduced Auditable Lines of Code" 
            page = {page} 
            pageNumber={4.7} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA8' 
            label = "High Gas Efficiency" 
            page = {page} 
            pageNumber={4.8} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
    ]
}

export function Functions({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'KA1' 
            label = "In-Place Storage Extendibility" 
            page = {page} 
            pageNumber={4.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA2' 
            label = "Seamless Interoperability" 
            page = {page} 
            pageNumber={4.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA3' 
            label = "Streamlined Development" 
            page = {page} 
            pageNumber={4.3} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
            />,
            <SidebarItem 
            key = 'KA4' 
            label = "Optimal Structural Efficiency" 
            page = {page} 
            pageNumber={4.4} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA5' 
            label = "Reduced Costs" 
            page = {page} 
            pageNumber={4.5} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA6' 
            label = "Native Access Controls" 
            page = {page} 
            pageNumber={4.6} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA7' 
            label = "Modular Design" 
            page = {page} 
            pageNumber={4.7} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'KA8' 
            label = "Vulnerability Mitigation" 
            page = {page} 
            pageNumber={4.8} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
    ]
}

export function PracticalUseCases({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'PUC1' 
            label = "In-Place Storage Extendibility" 
            page = {page} 
            pageNumber={2.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'PUC2' 
            label = "Seamless Cross-Contract Interoperability" 
            page = {page} 
            pageNumber={2.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'PUC3' 
            label = "Seamless Cross-Contract Interoperability" 
            page = {page} 
            pageNumber={2.3} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
            />,
            <SidebarItem 
            key = 'PUC4' 
            label = "Interoperability" 
            page = {page} 
            pageNumber={2.4} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
            />,
            <SidebarItem 
            key = 'PUC5' 
            label = "Contract Structure" 
            page = {page} 
            pageNumber={2.5} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
            <SidebarItem 
            key = 'PUC6' 
            label = "Auditable LOCs" 
            page = {page} 
            pageNumber={2.6} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
    ]
}
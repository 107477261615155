
import styles from '../../../CSS/SectionX.module.css';
import SubSection3ImageSpace from '../SubSection3ImageSpace';
import int from '../../../CSS/SectionX_Interactive.module.css';
import { Phase7_Interactive, Phase8_Interactive, Phase9_Interactive } from '../../../Animations/SectionX_Animations';
import { useEffect, useState } from 'react';
import StorageSection from './Phases/StorageSection';
import { PhaseData } from './Phases/PhaseData';
import gsap from 'gsap';
import SubSection3ImageSpace2 from '../SubSection3ImageSpace2';


export default function SubSection3()
{
    const [entries, setEntries] = useState(0);
    const [entries2, setEntries2] = useState(0);
    const [entries3, setEntries3] = useState(0);

    const [stones1, setStones1] = useState(0);
    const [stones2, setStones2] = useState(0);
    const [showStones, setShowStones] = useState(false);
    const [showNames, setShowNames] = useState(false)
    const [updateIds, setUpdateIds] = useState(false);
    const [specialPhase1Complete, setSpecialPhase1Complete] = useState(false);
    const [phase, setPhase] = useState(-1)

    const allPhaseData = PhaseData({
        setPhase,
        setEntries, 
        setEntries2,
        setEntries3,
        setShowStones,
        setStones1,
        setStones2,
        stones1,
        stones2,
        setShowNames,
        setUpdateIds,
        setSpecialPhase1Complete
    });

    useEffect(() => {

        if(specialPhase1Complete !== true) return;

        gsap.timeline({})
        .to("#FCH8", {opacity: 0, pointerEvents: "none"})
        .to("#StorageItems3", {position: "relative", opacity: 1})
        .to("#FCH9", {opacity: 1, pointerEvents: "all"})

        setPhase(14);

    }, [specialPhase1Complete])

    return(
        <div
            id = "SectionX_InteractiveContainer" 
            className={int.InteractiveContainer}
        >
            <SubSection3ImageSpace />
            <SubSection3ImageSpace2 />

            {allPhaseData.Phase1}
            {allPhaseData.Phase2}
            {allPhaseData.Phase3}
            {allPhaseData.Phase4}

            <div className = {`${int.IntroWrapperRow} `} id = "SectionX_IntroWrapper6">

                <StorageSection 
                    phase = {phase}
                    entries={entries}
                    entries2={entries2}
                    entries3={entries3}
                    showNames={showNames}
                    updateIds={updateIds}
                    showStones={showStones}
                    stones1={stones1}
                    stones2={stones2}
                />

                <div className = {int.TotalContainer}>
                
                    {allPhaseData.Phase5}
                    {allPhaseData.Phase6}
                    {allPhaseData.Phase7}
                    {allPhaseData.Phase8}
                    {allPhaseData.Phase9}
                    {allPhaseData.Phase10}
                    {allPhaseData.Phase11}
                    {allPhaseData.Phase12}
                    {allPhaseData.Phase13}
                    {allPhaseData.Phase14}
                    {allPhaseData.Phase15}
                    {allPhaseData.Phase16}
                    {allPhaseData.Phase17}
                    {allPhaseData.Phase18}
                    {allPhaseData.Phase19}
                </div>
            </div>
            
        </div>
    )
}
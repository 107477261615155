import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import GasSinglePut from '../../../../assets/GasSinglePut.png';
import GasSinglePutCode from '../../../../assets/GasSinglePutCode.png';
import GasCostsEquivalentOverhead from '../../../../assets/GasCostsEquivalentOverhead.png';
import GasCostsEquivalentOverheadComparison from '../../../../assets/GasCostsEquivalentOverheadComparison.png';
import styles from '../Common/page.module.css';
import Gas2Values from '../../../../assets/GasComparison2Values.png';
import Gas8Values from '../../../../assets/8ValuesGas.png';
import StringComparison from '../../../../assets/StringComparison.png';


export default function Page_5_1({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>

            <h2 className={styles.PageHeader}>
                Performance Benchmarks: Modifying Storage
            </h2>

            <P>
                This section provides a comprehensive overview of HoneyBadger's 
                storage related costs in comparison to basic high-level Solidity 
                models leveraging the Proxy-Delegate pattern.

            </P>

            <H>Notes</H>
            <P>
                Assume slots have been initialized.  Gas costs 
                are calculated with an ETH price of $3,000 and a gas price of 30 gwei.
            </P>
            <S />
            <img 
                style = {{border: "1px solid white"}}
                src = {GasSinglePut} 
                className = {styles.Image}
                alt = "Gas for a single put operation" />

            <p style = {{color: 'white'}}><i>Gas comparison: Proxy-Delegate vs HoneyBadger</i></p>
            <S />

            <H>Modifying a Single Value: Uint32</H>
            <P2>
                <i>Notes:</i> Using HoneyBadger's <i>put </i> function.
                
                <br/><br/>

                <b>Basic Implementation + Proxy-Delegate: </b> 7,288 gas
                <br/>
                <i>Cost Estimate:</i> $0.66 USD
                <br/><br/>

                <b>HoneyBadger:</b> 12,778 gas
                <br />

                <i>Cost Estimate:</i> $1.15 USD

                <br/><br/>
                HoneyBadger's <i>put</i> function is more expensive than 
                changing a value in high-level Solidity, as it introduces 
                additional safety features including access controls and input 
                validation.  Additionally, HoneyBadger's extendible storage structures  
                require custom data management, with storage slot 
                derivation adding additional overhead.  

            </P2>
            <S />
            <img 
                style = {{marginTop: "40px", border: "1px solid white"}}
                src = {GasSinglePutCode} 
                className = {styles.Image}
                alt = "Code for a single put operation: HoneyBadger vs Proxy-Delegate" />
            <p style = {{color: 'white'}}><i>Code comparison: Basic Example vs HoneyBadger</i></p>
            <S /> 

            <H>Modifying One Value: Equivalent Validations</H>


            <P>
                In this example, we convert the proxy-delegate system to include 
                access controls and input validation.
            </P>
            <S />
            <img 
                style = {{ border: "1px solid white"}}
                src = {GasCostsEquivalentOverhead} 
                className = {styles.Image}
                alt = "Single put costs, equivalent overhead" />
            <p style = {{color: 'white'}}><i>Gas comparison: Proxy-Delegate 
            With Validation vs HoneyBadger</i></p>
            <S />

            <P2>
                <i>Notes:</i> Using HoneyBadger's <i>put </i> function.
                
                <br/><br/>

                <b>Basic Implementation With Validation + Proxy-Delegate: </b> 32,011 gas
                <br/>
                <i>Cost Estimate:</i> $2.88 USD
                <br/><br/>

                <b>HoneyBadger:</b> 12,778 gas
                <br />

                <i>Cost Estimate:</i> $1.15 USD
                <br/><br/>

                HoneyBadger's high efficiency is evident 
                when equivalent security and input validation overhead 
                is implemented in high-level Solidity.  
                Advanced techniques including a streamlined assembly implementation, 
                custom metadata structures, and optimized bitwise operations significantly 
                reduce the cost of HoneyBadger's robust security measures. 
                
            </P2>
            <S />
            <img 
                style = {{marginTop: "40px", border: "1px solid white"}}
                src = {GasCostsEquivalentOverheadComparison} 
                className = {styles.Image}
                alt = "Single put costs, equivalent overhead" />
            <p style = {{color: 'white'}}><i>Code comparison: Proxy-Delegate validation 
            logic vs HoneyBadger validation logic.</i></p>
            <S />

            <H>Modifying Two Values </H>

            <P>
                In this example, we compare HoneyBadger's <i>put_batch</i> function 
                in the "Superfast" model (which includes access controls, but not input validation)
                to storing two values in a Delegate contract with basic 
                access controls.

                <br/><br/>

                <b>Basic Implementation With Access Controls + Proxy-Delegate: </b> 14,708 gas
                <br/>
                <i>Cost Estimate:</i> $2.88 USD
                <br/><br/>

                <b>HoneyBadger:</b> 10,051 gas
                <br />

                <i>Cost Estimate:</i> $0.90 USD
                <br/><br/>
            </P>

            <S />
            <img 
                style = {{ border: "1px solid white"}}
                src = {Gas2Values} 
                className = {styles.Image}
                alt = "HoneyBadger vs Proxy-Delegate: storing 2 values" />
            <p style = {{color: 'white'}}><i>
            Gas comparison: HoneyBadger Superfast (put_batch) 
            vs Proxy-Delegate with basic access controls.</i></p>
            <S />

            <H>Modifying Six Values </H>

            <P>
                Next, we compare Superfast to the basic high-level Solidity model
                with Proxy-Delegate when storing six values.
                

                <br/><br/>

                <b>Basic Implementation With Access Controls + Proxy-Delegate: </b> 22,019 gas
                <br/>
                <i>Cost Estimate:</i> $1.98 USD
                <br/><br/>

                <b>HoneyBadger:</b> 26,424 gas
                <br />

                <i>Cost Estimate:</i> $2.38 USD
                <br/><br/>
            </P>

            <S />
                <img 
                    style = {{ border: "1px solid white"}}
                    src = {Gas8Values} 
                    className = {styles.Image}
                    alt = "HoneyBadger vs Proxy-Delegate: storing 2 values" />
                <p style = {{color: 'white'}}><i>
                Gas comparison: HoneyBadger Superfast (put_batch) 
                vs Proxy-Delegate with basic access controls.</i></p>
            <S />
            <P>
                <b>Is Superfast safe? </b>The base model's input validation is not 
                technically necesesary for 
                system security: access controls already prevent unauthorized 
                entities from interacting with the system.  For Superfast to cause 
                issues, developers would need to write invalid logic.  For instance, 
                Superfast would <i>not </i> catch a call to an invalid 
                storage member.  

                <br/><br/>

                One strategy to ensure logical integrity with Superfast is to build 
                and test with the base model: the syntax is equivalent.  If the base 
                model accepts the desired operations, Superfast is safe to use.

                <br/><br/>

                Superfast does come with some drawbacks: it puts more responsibility 
                on the shoulders of the builder.  For instance, a Superfast model is 
                vulnerable to overflow attacks, making it important to understand 
                when input validation is necessary.  

            </P>

            <S/>

            <H>
                Storing Strings
            </H>

            <P>
                Now, we compare HoneyBadger's <i>put_string</i> function to 
                a string storage operation in high-level Solidity.  We use the 
                Superfast model for this example.

                <br/><br/>

                <b>Basic Implementation With Access Controls + Proxy-Delegate: </b> 5,792 gas
                <br/>
                <i>Cost Estimate:</i> $.60 USD
                <br/><br/>

                <b>HoneyBadger:</b> 14,851 gas
                <br />

                <i>Cost Estimate:</i> $1.34 USD
                <br/><br/>

            </P>

        </PageLayout>
        
    )
}
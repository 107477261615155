import { useState } from 'react';
import '../../../../css/keyFonts.css';
import styles from './ItemSegment.module.css';
import caret from '../../../../assets/caret.svg'
import '../../../../css/TextStyles.css'

export default function ItemSegment({items, title, open})
{
    const [isOpen, setOpen] = useState(open);

    return(
        <div className = {styles.ItemSegment}>
            <h2 className = {styles.Line} />
            <div className = {styles.ItemSegmentTitleContainer} onClick = {() => {setOpen(!isOpen)}}>
                <p className={`TrapBold ${styles.Heading}`}>{title}</p>
                <img src = {caret} className = {`${styles.Caret} ${isOpen ? styles.CaretOpen : ''}`}/>
            </div>
            {isOpen && items}
        </div>
    )
}

export function HiddenSegment({items})
{
    const [open, setOpen] = useState(true);

    return items;
}